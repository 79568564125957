import { FC, Fragment } from 'react'
import { Box, styled, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { DifferentiationValueSelector as AccessLevelValueSelector } from 'components'
import {
    ACTION_TYPES,
    checkAllRequiredFieldTypeFilled,
    checkFieldTypeWithValidSourceValues,
    findChangedFieldTypesForAppRight,
    findChangedFieldTypesForAppRole,
    getModifiedFieldTypeValue,
    updateJsonMappingWithApiFormat,
    useIsSmallScreen,
} from 'packages/core'
import { Skeleton } from '@mui/material'
import { AddToCart } from 'components/AddToCart'
import { IResourceType, ResourceTypesNamespace, useRegistry } from 'core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { useHistory } from 'react-router'
import appConfig from 'config'
import { Loader, useNotification } from 'packages/eid-ui'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useApplicationFieldTypes } from 'hooks'
import { ACCESS_LEVEL_TYPE } from 'utils'
import TextWithIcon from 'components/common/TextWithIcon'

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 32px',
    minHeight: '232px',
    display: 'flex',
})

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const useStyles = makeStyles((theme) => ({
    header: {
        boxShadow: '0 0.4rem 1.7rem 0 rgba(0, 0, 0, 0.07)',
        padding: '1.7rem 3.2rem 1.2rem',
        background: `repeating-linear-gradient(${theme?.palette?.common?.white}, ${theme?.palette?.common?.white} 3px, rgba(0, 0, 0, 0.02) 3px, rgba(0, 0, 0, 0.02) 4px)`,
        borderTop: 'solid 0.1rem #e8e8e8',
        borderBottom: 'solid 0.1rem #e8e8e8',

        '& h2': {
            fontSize: '1.4rem',
            fontWeight: '600',
        },
    },
}))

const GradientSpacer = styled(Box)({
    background:
        'linear-gradient(180deg, #e9e9eb 0%, #f7f7f9 50%, #f1f1f3 100%)',
    height: '2.4rem',
})

interface ApplicationAccessLevelProps {
    accessLevels: any
    accessLevel: any
    setAccessLevelValue: (val: any) => void
    isCheckingAccess: boolean
    application: any
    targetPerson: string
    fieldTypeSelector?: any
    isFormValidated?: boolean
    setIsFormValidated?: any
}
const ApplicationAccessLevel: FC<ApplicationAccessLevelProps> = ({
    accessLevels,
    accessLevel,
    targetPerson,
    setAccessLevelValue,
    isCheckingAccess,
    application,
    fieldTypeSelector,
    isFormValidated = true,
    setIsFormValidated,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const registry = useRegistry()
    const history = useHistory()
    const { showWarningMessage } = useNotification()
    const [{ appRightsFieldTypes }, dispatch]: any = useResourceTypeContext()

    const applicationsType: IResourceType = registry.get(
        ResourceTypesNamespace,
        'Applications',
    )

    // To Fetch the application fieldType from react-query

    const { data } = useApplicationFieldTypes(
        null,
        accessLevel?.resourceAssignment?.resourceTypeName ===
            ACCESS_LEVEL_TYPE.AZURE_ROLE
            ? accessLevel?.resourceAssignment?.id
            : null,
        accessLevel?.resourceAssignment?.resourceTypeName ===
            ACCESS_LEVEL_TYPE.AZ_LOCAL_RIGHT
            ? accessLevel?.resourceAssignment?.id
            : null,
        Boolean(application?.isPBACApplication),
    )

    const isSmallScreen = useIsSmallScreen()

    const getPreValidationMessage = () =>
        !Boolean(accessLevel) ? t('Common_SelectAccessLevel') : ''

    const bindUpdatedFieldTypeValues = () => {
        const originalData = JSON.parse(data)
        const parsedObject = Array.isArray(originalData)
            ? originalData[0]
            : originalData
        const parsedMappedWithApiFormat = updateJsonMappingWithApiFormat(
            parsedObject,
            false,
        )

        if (
            accessLevel?.resourceAssignment?.resourceTypeName ===
            ACCESS_LEVEL_TYPE.AZURE_ROLE
        ) {
            return updateJsonMappingWithApiFormat(
                findChangedFieldTypesForAppRole(
                    parsedMappedWithApiFormat,
                    appRightsFieldTypes,
                    ['SourceValues', 'totalCount'],
                ),
                true,
            )
        } else {
            return updateJsonMappingWithApiFormat(
                findChangedFieldTypesForAppRight(
                    parsedMappedWithApiFormat,
                    appRightsFieldTypes,
                    ['SourceValues', 'totalCount'],
                ),
                true,
            )
        }
    }

    const preAddItem = (baseObj: any) => {
        const itemToAdd = cartHelpers.applicationsAccessToRolesToCartItem({
            targetPerson,
            assignmentType: 'Add',
            application,
            assignmentStatus: accessLevel,
            fieldTypeValueJson: appRightsFieldTypes
                ? JSON.stringify([bindUpdatedFieldTypeValues()])
                : undefined,
            ...baseObj,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const preAddSplitted = (baseObj: any, fieldType: any) => {
        const itemToAdd = cartHelpers.applicationsAccessToRolesToCartItem({
            targetPerson,
            assignmentType: 'Add',
            application,
            assignmentStatus: accessLevel,
            fieldTypeValueJson: fieldType
                ? JSON.stringify([fieldType])
                : undefined,
            ...baseObj,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const preAdd = (baseObj: any) => {
        if (checkIfValidJson()) {
            if (checkIfAllRequiredFieldFilled()) {
                if (
                    appRightsFieldTypes?.SplitBusinessRequestApprovalPerFieldTypeValue
                ) {
                    const apiDataResponse = JSON.parse(data)
                    const dataObject = Array.isArray(apiDataResponse)
                        ? apiDataResponse[0]
                        : apiDataResponse

                    const parsedObject = updateJsonMappingWithApiFormat(
                        dataObject,
                        false,
                    )
                    let cartItems: any = []
                    const fieldTypes = appRightsFieldTypes?.FieldType || []
                    const ordinalFieldTypeData = parsedObject?.FieldType || []
                    fieldTypes.forEach((item: any) => {
                        const fieldType: any = getModifiedFieldTypeValue(
                            ordinalFieldTypeData,
                            item,
                        )
                        if (fieldType?.isModified) {
                            const parentObject = { ...appRightsFieldTypes }
                            if (fieldType?.AzFieldTypeID) {
                                const valueType =
                                    fieldType?.AzFieldTypeSelectionRuleTypeID ===
                                        3
                                        ? 'AssignedValues'
                                        : 'AssignedValue'
                                let fieldValue = fieldType[valueType]
                                if (Array.isArray(fieldValue)) {
                                    const fieldToAdd = fieldValue.filter(
                                        (x: any) => x.ActionType !== ACTION_TYPES.NO_CHANGE,
                                    )
                                    fieldToAdd.forEach((field: any) => {
                                        const itemToAdd: any = preAddSplitted(
                                            baseObj,
                                            {
                                                ...parentObject,
                                                FieldType: [
                                                    {
                                                        ...fieldType,
                                                        [valueType]: [field],
                                                    },
                                                ],
                                            },
                                        )
                                        cartItems.push(itemToAdd)
                                    })
                                } else {
                                    if (
                                        fieldType?.AzFieldTypeSelectionRuleTypeID !==
                                        3 &&
                                        fieldValue
                                    ) {
                                        fieldValue = [fieldValue]
                                    }
                                    const itemToAdd: any = preAddSplitted(
                                        baseObj,
                                        {
                                            ...parentObject,
                                            FieldType: [
                                                {
                                                    ...fieldType,
                                                    [valueType]: fieldValue,
                                                },
                                            ],
                                        },
                                    )
                                    cartItems.push(itemToAdd)
                                }
                            }
                        }
                    })

                    if (!cartItems?.length) return

                    return cartItems
                } else {
                    const itemToAdd: any = preAddItem(baseObj)
                    if (!itemToAdd) return undefined

                    if (itemToAdd.resourceType_Name === 'AzureRole') {
                        itemToAdd.resourceType_Name = 'RoleDefinition'
                    }
                    return itemToAdd
                }
            } else {
                showWarningMessage(t('Common_RequiredFieldMissingValues'))
                if (setIsFormValidated) {
                    setIsFormValidated(true)
                }
                return undefined
            }
        } else {
            showWarningMessage(t('Common_WronglyConfiguredJson'))
            return undefined
        }
    }

    const checkIfAllRequiredFieldFilled = () => {
        const data = { ...appRightsFieldTypes }
        let isRequiredFilled = true
        try {
            if (data?.Rights?.length) {
                data?.Rights?.forEach((right: any) => {
                    if (right?.FieldType?.length) {
                        right?.FieldType?.forEach((item: any) => {
                            if (!checkAllRequiredFieldTypeFilled(item)) {
                                isRequiredFilled = false
                            }
                        })
                    }
                })
            } else {
                if (data?.FieldType?.length) {
                    data?.FieldType?.forEach((item: any) => {
                        if (!checkAllRequiredFieldTypeFilled(item)) {
                            isRequiredFilled = false
                        }
                    })
                }
            }
        } catch (err) { }
        return isRequiredFilled
    }

    const checkIfValidJson = () => {
        const data = { ...appRightsFieldTypes }
        let isValidJson = true
        if (!isFormValidated) {
            return isValidJson
        }

        try {
            if (data?.Rights?.length) {
                data?.Rights?.forEach((right: any) => {
                    if (right?.FieldType?.length) {
                        right?.FieldType?.forEach((item: any) => {
                            if (!checkFieldTypeWithValidSourceValues(item)) {
                                isValidJson = false
                            }
                        })
                    }
                })
            } else {
                if (data?.FieldType?.length) {
                    data?.FieldType?.forEach((item: any) => {
                        if (!checkFieldTypeWithValidSourceValues(item)) {
                            isValidJson = false
                        }
                    })
                }
            }
        } catch (err) { }
        return isValidJson
    }

    return (
        <Fragment>
            {!isSmallScreen && (
                <>
                    <GradientSpacer />
                    <Box className={classes.header}>
                        <Typography variant="h2">
                            {t('Common_ApplicationRoleOrRight')}
                        </Typography>
                    </Box>
                    <PromptContainer>
                        <Box
                            width="172px"
                            display="flex"
                            flexDirection="column"
                            zIndex={1}
                        >
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="center"
                            >
                                <Icon
                                    name="PermissionLevel"
                                    height="150px"
                                    width="150px"
                                    color="#D2D2D9"
                                />
                            </Box>
                        </Box>
                        <Box width="334px" paddingLeft="16px">
                            {isCheckingAccess ? (
                                <Loader />
                            ) : (
                                <AccessLevelValueSelector
                                    getTooltipText={(i: any) => {
                                        let resourceTypeName =
                                            i?.resourceAssignment
                                                ?.resourceTypeName
                                        if (resourceTypeName === 'AzureRole') {
                                            resourceTypeName = 'RoleDefinition'
                                        }
                                        return `${t(
                                            `Common_${resourceTypeName}`,
                                        )}/${i?.resourceAssignment
                                                ?.description ||
                                            i?.resourceAssignment
                                                ?.friendlyName ||
                                            i?.resourceAssignment?.name
                                            }`
                                    }}
                                    loading={!accessLevels}
                                    onChange={(val: any) =>
                                        setAccessLevelValue(val)
                                    }
                                    data={
                                        accessLevels &&
                                        accessLevels.filter(
                                            (o: any) => !o.isAssigned,
                                        )
                                    }
                                    value={accessLevel?.resourceAssignment.id}
                                    getOptionLabel={(o: any) =>
                                        o.resourceAssignment.friendlyName
                                    }
                                    checkSelectedOption={(
                                        o: any,
                                        selectedValueId: any,
                                    ) =>
                                        o.resourceAssignment.id ===
                                        selectedValueId
                                    }
                                    // NOTE: This is a quick fix as per new Ui. as the component is getting used at other places too.
                                    readOnlyOptions={[]}
                                />
                            )}
                        </Box>
                        <Box sx={{ ml: '3.2rem' }}>
                            {accessLevel?.resourceAssignment?.instructions && (
                                <TextWithIcon
                                    iconName={'InfoNew'}
                                    message={
                                        accessLevel?.resourceAssignment
                                            ?.instructions
                                    }
                                />
                            )}
                        </Box>
                    </PromptContainer>
                    {fieldTypeSelector}
                    <Box>
                        {!application ? (
                            <Box padding={'24px'}>
                                <Skeleton height={40} />
                            </Box>
                        ) : (
                            <AddToCart
                                useAccessLevelPolicy={true}
                                resourceType={applicationsType}
                                resource={application}
                                secondary={accessLevel}
                                preValidate={getPreValidationMessage}
                                preAdd={preAdd}
                                isMultiCartItems={
                                    appRightsFieldTypes?.SplitBusinessRequestApprovalPerFieldTypeValue ||
                                    false
                                }
                                postAdd={() => {
                                    dispatch({
                                        type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                        payload: null,
                                    })
                                    history.push(
                                        `${appConfig.APP_SUBPATH}/applications`,
                                    )
                                }}
                            />
                        )}
                    </Box>
                </>
            )}

            {isSmallScreen && (
                <PaddedDiv>
                    <Box
                        paddingY="24px"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <Box position="absolute" top="16px" right="0">
                            <Icon
                                name="PermissionLevel"
                                height="100px"
                                width="100px"
                                color="#D2D2D9"
                            />
                        </Box>
                        <Box width="172px" paddingY="4px" zIndex={1}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    lineHeight: 1.43,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {t('Common_ApplicationRoleOrRight')}
                            </Typography>
                        </Box>
                        <Box paddingY="16px">
                            <AccessLevelValueSelector
                                getTooltipText={(i: any) => {
                                    let resourceTypeName =
                                        i?.resourceAssignment?.resourceTypeName
                                    if (resourceTypeName === 'AzureRole') {
                                        resourceTypeName = 'RoleDefinition'
                                    }
                                    return `${t(
                                        `Common_${resourceTypeName}`,
                                    )}/${i?.resourceAssignment?.description ||
                                        i?.resourceAssignment?.friendlyName ||
                                        i?.resourceAssignment?.name
                                        }`
                                }}
                                loading={!accessLevels}
                                onChange={(val: any) =>
                                    setAccessLevelValue(val)
                                }
                                data={
                                    accessLevels &&
                                    accessLevels.filter(
                                        (o: any) => !o.isAssigned,
                                    )
                                }
                                value={
                                    accessLevel?.resourceAssignment
                                        .resourceAssignmentId
                                }
                                getOptionLabel={(o: any) =>
                                    o.resourceAssignment.friendlyName
                                }
                                checkSelectedOption={(
                                    o: any,
                                    selectedValueId: any,
                                ) =>
                                    o.resourceAssignment
                                        .resourceAssignmentId ===
                                    selectedValueId
                                }
                                readOnlyOptions={
                                    accessLevels &&
                                    accessLevels.filter(
                                        (o: any) => o.isAssigned,
                                    )
                                }
                            />
                        </Box>
                        <Box width="100%">
                            {!application ? (
                                <Box padding={'24px'}>
                                    <Skeleton height={40} />
                                </Box>
                            ) : (
                                <AddToCart
                                    resourceType={applicationsType}
                                    resource={application}
                                    secondary={accessLevel}
                                    preValidate={getPreValidationMessage}
                                    preAdd={preAdd}
                                    useAccessLevelPolicy={true}
                                    postAdd={() => {
                                        dispatch({
                                            type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                            payload: null,
                                        })
                                        history.push(
                                            `${appConfig.APP_SUBPATH}/applications`,
                                        )
                                    }}
                                    isSmallScreen={isSmallScreen}
                                />
                            )}
                        </Box>
                    </Box>
                </PaddedDiv>
            )}
        </Fragment>
    )
}

export default ApplicationAccessLevel
