import React, { useState, useEffect, createRef, useRef, Fragment } from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    Box,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Button from './Button'
import clsx from 'clsx'
import {
    FieldTypeValuesAssignmentPopup,
    Loader,
    Tooltip,
    WarningModal,
    useNotification,
} from 'packages/eid-ui'
import TextFormatter from './TextFormatter'
import { useTranslation } from 'react-i18next'
import { getUniqueId } from './resourceTypeHelpers'
import {
    useTargetPerson,
    useAddItemToCart,
    useAccessRequestPolicy,
    useRenewableAssignmentInfo,
    useAllCarts,
    useCart,
    useRemoveItemsFromCart,
} from 'hooks'
import cartHelpers from 'containers/Cart/cartHelpers'
import DynamicComponents from 'components/DynamicComponents'
import { CredentialsActionsButton } from 'components/CredentialsActions/CredentialsActionsButton'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { MasterPasswordLock } from 'components'
import { useAppState } from 'appContext'
import {
    hasValidJson,
    updateJsonMappingWithApiFormat,
    useQuery,
} from 'packages/core'
import ActivateNowButton from '../ListingPage/ActivateNowButton'
import {
    ACCESS_LEVEL_TYPE,
    getMasterLockFieldsWidth,
    getMasterLockLeftSpace,
    RESOURCE_TYPE_NAMES,
} from 'utils'
import { ResourceListingInfoIcon } from 'components/DynamicComponents/ResourceListingInfoIcon'
import classNames from 'classnames'

const maxCharacters = 80

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
}

var getValue = (ci, a) => {
    let value
    let resourceObj = ci?.resource ? ci?.resource : ci
    try {
        switch (a.name) {
            case 'differentiationValue':
                value =
                    ci?.assignment?.resourceAssignment?.locationFriendlyName ||
                    '-'
                break
            case 'accessLevel':
                value = ci?.assignment?.resourceAssignment?.friendlyName || '-'
                break
            case 'assignee':
                value =
                    ci?.assignment?.resourceAssignment?.assigneePreviewName ||
                    '-'
                break
            default:
                value = (resourceObj && resourceObj[a.name]) || '-'
        }

        return value ?? '-'
    } catch (err) {
        return '-'
    }
}

export const FieldTypeJsonPreview = ({ ci, rightMargin }) => {
    const getParsedObject = (apiDataObject) => {
        try {
            const parsedObject = Array.isArray(apiDataObject)
                ? apiDataObject[0]
                : apiDataObject
            return parsedObject
        } catch (err) {}
        return apiDataObject
    }
    if (!ci?.resource?.assignedFieldTypeValuePreviewJson) {
        return null
    }
    return (
        <Fragment>
            {ci?.resource?.assignedFieldTypeValuePreviewJson &&
                hasValidJson(
                    updateJsonMappingWithApiFormat(
                        getParsedObject(
                            JSON.parse(
                                ci?.resource?.assignedFieldTypeValuePreviewJson,
                            ),
                        ),
                        false,
                    ),
                ) && (
                    <Box sx={{ marginRight: rightMargin || '0' }}>
                        <FieldTypeValuesAssignmentPopup
                            fieldTypeValueJson={updateJsonMappingWithApiFormat(
                                getParsedObject(
                                    JSON.parse(
                                        ci?.resource
                                            ?.assignedFieldTypeValuePreviewJson,
                                    ),
                                ),
                                false,
                            )}
                            withBorder={true}
                            showLabel={false}
                        />
                    </Box>
                )}
        </Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: theme?.palette?.background?.paper,
        position: 'relative',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        maxWidth: '250px',
        overflow: 'clip',
        padding: '15px 6px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '20px !important',
        },
    },
    tableHeaderCell: {
        maxWidth: '180px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '13px !important',
        backgroundColor: '#fbfbfd',
        '& svg': {
            margin: '0px !important',
        },
    },
    tableActionCell: {
        padding: '15px 6px',
        paddingRight: '20px',
        borderBottom: '0',
        '& > button:nth-child(2)': {
            marginLeft: '12px',
        },
    },
    tableSortLabel: {
        color: '#7d7c7c !important',
        fill: '#7d7c7c !important',

        '& svg': {
            color: `#D2D2D9 !important`,
            marginLeft: '4px !important',
        },

        '&:hover': {
            color: `${theme?.palette?.primary?.main} !important`,

            '& svg': {
                color: `${theme?.palette?.primary?.main} !important`,
            },
        },
    },
    activeTableSortLabel: {
        '& svg': {
            color: `${theme?.palette?.primary?.main} !important`,
        },
    },
    detailsBox: {
        '& button': {
            borderRadius: '4px',
        },
    },
    disabledButton: {
        pointerEvents: 'none',
        '& button': {
            backgroundColor: '#aab0b4 !important',
            color: `${theme?.palette?.common?.white} !important`,
            borderColor: '#aab0b4 !important',
            cursor: 'not-allowed',
        },
    },
}))

const RevokeButton = ({ resourceType, ci }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const [showConfirm, setConfirm] = useState(false)
    const [targetPerson] = useTargetPerson()
    const { data: cart } = useCart()
    const [removeItemsFromCart, { isLoading: isRemoving }] =
        useRemoveItemsFromCart()

    const cartItem = cart?.cartItems.filter(
        (cItem) =>
            cItem.requestableResourceId ===
                ci?.resource?.[
                    ci?.resource?.resourceTypeName ===
                    ACCESS_LEVEL_TYPE.AZURE_ROLE
                        ? 'azLocalRoleId'
                        : 'localRightId'
                ] && cItem?.assignmentId === ci?.resource?.id,
    )

    const itemAlreadyInCartForRemoval = () => {
        const isInCart = cartItem?.find(
            (x) =>
                x.assignmentType === 'Remove' &&
                x?.assignmentId === ci?.resource?.id,
        )
            ? true
            : false
        return isInCart
    }

    const itemAlreadyInCart = () => {
        const isInCart = cartItem?.find(
            (x) => x?.assignmentId === ci?.resource?.id,
        )
            ? true
            : false
        return isInCart
    }

    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()
    // Delete Existing item if any for Edit mode

    const getDataObject = () => {
        if (
            resourceType === RESOURCE_TYPE_NAMES.APPLICATIONS &&
            (ci?.resource?.resourceTypeName === ACCESS_LEVEL_TYPE.AZURE_ROLE ||
                ci?.resource?.resourceTypeName ===
                    ACCESS_LEVEL_TYPE.AZ_LOCAL_RIGHT)
        ) {
            return {
                ...ci,
                resource: {
                    ...ci.resource,
                    id:
                        ci?.resource?.resourceTypeName ===
                        ACCESS_LEVEL_TYPE.AZURE_ROLE
                            ? ci.resource.azLocalRoleId
                            : ci.resource.localRightId,
                },
                assignment: {
                    ...ci.assignment,
                    assignmentId: ci?.resource?.id,
                    resourceAssignment: {
                        id: ci.resource.applicationId,
                        friendlyName: ci.resource.assignmentPointName,
                    },
                },
            }
        } else {
            return ci
        }
    }

    const handleRevoke = () => {
        if (cartItem?.length) {
            removeAndAddItemsToCart()
        } else {
            handleAddToCart()
        }
    }

    const removeAndAddItemsToCart = () => {
        try {
            const cartItemIds = cartItem.map((cItem) => cItem.id)
            removeItemsFromCart(cartItemIds).then(() => {
                handleAddToCart()
            })
        } catch (err) {}
    }

    const handleAddToCart = () => {
        const itemToRevoke = cartHelpers.accessItemToCartItem(
            resourceType !== 'AzureRoles'
                ? resourceType
                : ci.resource.roleGroupType === 'Rbac'
                ? 'AzureRbacRoles'
                : 'AzureAdminRoles',
            targetPerson,
            getDataObject(),
            'Remove',
            { timeConstrained: false },
        )
        if (!itemToRevoke) return

        addItemToCart(itemToRevoke).then(() => setConfirm(false))
    }

    return (
        <>
            <Tooltip
                title={
                    Boolean(itemAlreadyInCartForRemoval())
                        ? t('Common_ItemAlreadyInCart')
                        : ''
                }
            >
                <Box
                    className={classNames({
                        [classes.disabledButton]: Boolean(
                            Boolean(itemAlreadyInCartForRemoval()),
                        ),
                    })}
                >
                    <Button
                        color={theme?.palette?.error?.main}
                        onClick={() => {
                            !Boolean(itemAlreadyInCartForRemoval()) &&
                                setConfirm(true)
                        }}
                    >
                        {t('Common_Revoke')}
                    </Button>
                </Box>
            </Tooltip>
            <WarningModal
                title={t('Common_Confirm')}
                description={
                    resourceType === 'Computers'
                        ? t('Common_ConfirmationBeforeRevocationComputer')
                        : resourceType === RESOURCE_TYPE_NAMES.APPLICATIONS &&
                          (ci?.resource?.resourceTypeName ===
                              ACCESS_LEVEL_TYPE.AZURE_ROLE ||
                              ci?.resource?.resourceTypeName ===
                                  ACCESS_LEVEL_TYPE.AZ_LOCAL_RIGHT) &&
                          Boolean(itemAlreadyInCart())
                        ? t('Common_ItemAlreadyInCartForEdit')
                        : t('Common_ConfirmationBeforeRevocation')
                }
                yesLabel={t('Common_Yes')}
                noLabel={t('Common_No')}
                open={showConfirm}
                cancel={() => setConfirm(false)}
                proceeding={isAdding || isRemoving}
                proceed={handleRevoke}
            />
        </>
    )
}
const RenewButton = ({ resourceType, ci, cart }) => {
    const theme = useTheme()
    const isBusinessRole = resourceType === 'BusinessRoles'
    const [enableApiCall, setEnableApiCall] = React.useState(false)
    const [disableButton, setDisableButton] = React.useState(false)
    const [isRequestable, setIsRequestable] = React.useState(true)
    const requestPolicyId = ci.resource.requestPolicyId
    const locationId = ci?.assignment?.resourceAssignment?.locationId
        ? ci.assignment.resourceAssignment.locationId
        : ''

    const accessRequestPolicy = useAccessRequestPolicy(
        requestPolicyId,
        isBusinessRole ? ci.resource.id : '',
        isBusinessRole ? locationId : '',
    )
    useEffect(() => {
        if (accessRequestPolicy && !accessRequestPolicy.isRequestAllowed) {
            setIsRequestable(false)
        }
    }, [accessRequestPolicy])

    const { isLoading, data: renewableData } = useRenewableAssignmentInfo(
        ci.assignment?.personId,
        isBusinessRole
            ? ci.assignment?.resourceAssignment?.resourceAssignmentId
            : ci.assignment?.resourceAssignment?.resourceId,
        isBusinessRole,
        enableApiCall,
    )
    const { showWarningMessage } = useNotification()

    useEffect(() => {
        if (disableButton) {
            setEnableApiCall(false)
        }
        setDisableButton(false)
        isItemAlreadyInCart()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableButton])

    useEffect(() => {
        if (enableApiCall && renewableData && renewableData.isRenewable) {
            addToCartForRenew()
        } else if (
            enableApiCall &&
            renewableData &&
            renewableData.isRenewable === false
        ) {
            setDisableButton(true)
            showWarningMessage(t('Common_UnableToRenewMessage'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, renewableData, enableApiCall])
    const isTimeConstrained =
        ci.assignment.assignmentDetails?.timeConstraintActive

    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()

    const [addItemToCart] = useAddItemToCart()
    const handleRenewal = () => {
        setEnableApiCall(true)
    }
    const addToCartForRenew = () => {
        let maxEndDate
        if (accessRequestPolicy && accessRequestPolicy.maximumValueInMinutes) {
            maxEndDate = moment()
                .clone()
                .add(accessRequestPolicy.maximumValueInMinutes, 'minutes')
        } else if (
            isTimeConstrained &&
            ci.assignment.assignmentDetails.startDateUtc &&
            ci.assignment.assignmentDetails.endDateUtc
        ) {
            const diff = Math.abs(
                new Date(ci.assignment.assignmentDetails.startDateUtc) -
                    new Date(ci.assignment.assignmentDetails.endDateUtc),
            )
            const minutes = Math.floor(diff / 1000 / 60)
            maxEndDate = moment().clone().add(minutes, 'minutes')
        }
        const timeConstraintDetails = {
            timeConstrained: isTimeConstrained,
            startDateUtc: moment().utc(),
            endDateUtc: maxEndDate.utc(),
            timebased: isTimeConstrained,
        }

        const itemToRenew = cartHelpers.accessItemToCartItem(
            resourceType !== 'AzureRoles'
                ? resourceType
                : ci.resource.roleGroupType === 'Rbac'
                ? 'AzureRbacRoles'
                : 'AzureAdminRoles',
            targetPerson,
            ci,
            'Add',
            timeConstraintDetails,
        )
        if (!itemToRenew) return

        addItemToCart(itemToRenew).then(setDisableButton(true))
    }
    const isItemAlreadyInCart = () => {
        if (cart && cart.length > 0) {
            const targetPersonCart = cart.filter(
                (el) => el.targetPersonId === ci.assignment?.personId,
            )
            if (targetPersonCart && targetPersonCart.length > 0) {
                const item = targetPersonCart[0].cartItems.filter(
                    (el) =>
                        el.requestableResourceId === ci.resource?.id &&
                        el.locationId ===
                            ci.assignment.resourceAssignment.locationId,
                )

                if (item && item.length > 0) {
                    setDisableButton(true)
                }
            }
        }
    }
    if (!accessRequestPolicy) {
        return <Loader height={20}></Loader>
    }
    if (!isRequestable) {
        return <RevokeButton resourceType={resourceType} ci={ci}></RevokeButton>
    }
    return (
        <>
            <Button
                fill={
                    !disableButton
                        ? theme?.palette?.primary?.main
                        : theme?.palette?.disabled?.main
                }
                isFilled
                color={
                    !disableButton
                        ? theme?.palette?.primary?.main
                        : theme?.palette?.disabled?.main
                }
                onClick={!disableButton ? handleRenewal : null}
                isDisabled={disableButton}
            >
                {' '}
                <span>{t('Common_Renew')}</span>
                <Icon
                    name="ArrowSmall"
                    fill="white"
                    color="white"
                    direction="right"
                    style={{ marginLeft: '5px' }}
                />
            </Button>
        </>
    )
}

const renderComponent = (attribute, data) => {
    const DynamicComponentToRender = DynamicComponents[attribute.component.name]

    return <DynamicComponentToRender attribute={attribute} data={data} />
}

const TableView = ({
    data,
    attributes,
    onSort,
    loading,
    fallbackMessage,
    onItemClick,
    currentResourceType,
    sortBy,
    sortOrder,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const headings = attributes
        .filter((rt) => !rt.hideInManageAccess)
        .map((a) => ({
            ...a,
            label: t(a.label),
            sort: a.sortable ? a.sortable : false,
        }))

    const { data: allCarts } = useAllCarts()

    const toolTipValue = (items) => {
        const newItems = []
        items.map((item) => newItems.push(item.friendlyName))
        return newItems.join(`, `)
    }

    const handleSort = (data) => {
        const sortingProperty =
            data.manageAccessColumnName &&
            data.manageAccessColumnName.length > 0
                ? data.manageAccessColumnName
                : data.name
        onSort(
            data,
            sortBy !== sortingProperty
                ? 'desc'
                : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                ? 'desc'
                : 'asc',
        )
    }

    const classes = useStyles({})

    const query = useQuery()
    const selectedTab = query.get('tab')

    const showCredentialActions =
        useRouteMatch({
            path: '/credentials/manageAccess',
        }) && selectedTab === 'credentials'

    const isCredentialsSecretsTab =
        useRouteMatch({
            path: '/credentials/manageAccess',
        }) && selectedTab === 'secrets'

    // State and logic to bind master lock on columns Start
    const [{ masterPassword }] = useAppState()

    const [masterPasswordLeftWidth, setMasterPasswordLeftWidth] = useState()
    const [masterPasswordWidth, setMasterPasswordWidth] = useState()

    const tableHeadingRefs = useRef(
        headings?.length > 0 ? headings.map(() => createRef()) : null,
    )

    const getCorespondingRef = (name) => {
        const itemIndex = headings.findIndex((x) => x.name === name)
        return itemIndex >= 0 ? tableHeadingRefs.current[itemIndex] : null
    }

    const handleResize = () => {
        setMasterPasswordLeftWidth(
            getMasterLockLeftSpace(headings, tableHeadingRefs),
        )
        setMasterPasswordWidth(
            getMasterLockFieldsWidth(headings, tableHeadingRefs),
        )
    }

    let showMasterLock = false
    const masterPasswordAttributes = attributes.filter(
        (rt) => !rt.hideInManageAccess && rt.showMasterLock,
    )
    if (selectedTab) {
        const selectedTabInfo = currentResourceType?.manageAccessTabs
            ? currentResourceType.manageAccessTabs.find(
                  (x) => x.path === selectedTab,
              )
            : null
        showMasterLock =
            selectedTabInfo?.showMasterLock &&
            masterPasswordAttributes.length > 0
    }

    useEffect(() => {
        if (data && showMasterLock) {
            handleResize()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, headings, showMasterLock])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const masterPasswordLockStyle = {
        height: 'calc(100% - 50px)',
        width: '100%',
        maxWidth: `${masterPasswordWidth}px`,
        left: `${masterPasswordLeftWidth}px`,
    }

    // State and logic to bind master lock on columns End

    return (
        <>
            <Table id="identities_list_table" className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        {headings.map((heading, index) => {
                            const sortingProperty =
                                heading.manageAccessColumnName &&
                                heading.manageAccessColumnName.length > 0
                                    ? heading.manageAccessColumnName
                                    : heading.name
                            return (
                                <TableCell
                                    key={'pd-head-cell-' + index}
                                    className={clsx(
                                        classes.tableHeaderCell,
                                        classes.tableCell,
                                        classes.headGrey,
                                    )}
                                    align={heading.align}
                                    ref={getCorespondingRef(heading.name)}
                                    data-protectedsubcomponent={
                                        heading?.requireAccess?.control || ''
                                    }
                                >
                                    {!heading.sort ? (
                                        <span style={{ display: 'flex' }}>
                                            {heading.label}
                                        </span>
                                    ) : (
                                        <span style={{ display: 'flex' }}>
                                            <Tooltip
                                                title={
                                                    sortBy ===
                                                        sortingProperty &&
                                                    sortOrder === 'desc'
                                                        ? t(
                                                              'Common_SortAscending',
                                                          )
                                                        : t(
                                                              'Common_SortDescending',
                                                          )
                                                }
                                            >
                                                <TableSortLabel
                                                    active={heading.sort}
                                                    direction={
                                                        sortBy ===
                                                        sortingProperty
                                                            ? sortOrder
                                                            : 'asc'
                                                    }
                                                    className={
                                                        sortBy !==
                                                        sortingProperty
                                                            ? classes.tableSortLabel
                                                            : clsx(
                                                                  classes.tableSortLabel,
                                                                  classes.activeTableSortLabel,
                                                              )
                                                    }
                                                    style={{
                                                        color:
                                                            sortBy ===
                                                                sortingProperty &&
                                                            'yellow !important',
                                                    }}
                                                    onClick={() =>
                                                        handleSort(heading)
                                                    }
                                                >
                                                    {heading.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </span>
                                    )}
                                </TableCell>
                            )
                        })}
                        <TableCell
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                        ></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell
                                colSpan={headings.length + 1}
                                style={{ textAlign: 'center' }}
                            >
                                <Loader />
                            </TableCell>
                        </TableRow>
                    )}
                    {data &&
                        (data.length === 0 ? (
                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    colSpan={headings.length + 1}
                                    style={{ textAlign: 'center' }}
                                >
                                    {fallbackMessage}
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {!masterPassword &&
                                    showMasterLock &&
                                    masterPasswordAttributes.length > 0 && (
                                        <MasterPasswordLock
                                            style={masterPasswordLockStyle}
                                        />
                                    )}
                                {data.map((ci, index) => {
                                    const isRevokable =
                                        ci.assignment?.assignmentDetails
                                            ?.isRevokable
                                    const isRenewable =
                                        ci.assignment?.isRenewable

                                    const showActivateNow =
                                        ci.assignment?.canActivateNow

                                    return (
                                        <TableRow
                                            key={`${getUniqueId(
                                                ci,
                                                selectedTab,
                                            )}-${index} `}
                                            className={classes.tableRow}
                                        >
                                            {attributes
                                                .filter(
                                                    (rt) =>
                                                        !rt.hideInManageAccess,
                                                )
                                                .map((a) => {
                                                    let wrappedCellContent

                                                    if (a.component) {
                                                        wrappedCellContent =
                                                            renderComponent(
                                                                a,
                                                                ci,
                                                            )
                                                    } else if (
                                                        a.type !== 'array'
                                                    ) {
                                                        const cellContent = (
                                                            <Box
                                                                maxHeight="200px"
                                                                display="block"
                                                                maxWidth="200px"
                                                                overflow="auto"
                                                                style={{
                                                                    display:
                                                                        'block',
                                                                    wordBreak:
                                                                        'break-word',
                                                                    wordWrap:
                                                                        'break-word',
                                                                }}
                                                            >
                                                                {getValue(ci, a)
                                                                    .length >
                                                                maxCharacters
                                                                    ? `${getValue(
                                                                          ci,
                                                                          a,
                                                                      ).substring(
                                                                          0,
                                                                          maxCharacters,
                                                                      )}...`
                                                                    : getValue(
                                                                          ci,
                                                                          a,
                                                                      )}
                                                            </Box>
                                                        )
                                                        wrappedCellContent =
                                                            getValue(ci, a)
                                                                .length >
                                                            maxCharacters ? (
                                                                <Tooltip
                                                                    title={getValue(
                                                                        ci,
                                                                        a,
                                                                    )}
                                                                    color="#5D6870"
                                                                    fontColor={
                                                                        theme
                                                                            .palette
                                                                            .common
                                                                            .white
                                                                    }
                                                                    enterDelay={
                                                                        500
                                                                    }
                                                                    enterNextDelay={
                                                                        500
                                                                    }
                                                                    disableInteractive={
                                                                        false
                                                                    }
                                                                    tooltipStyles={
                                                                        tooltipStyles
                                                                    }
                                                                >
                                                                    {
                                                                        cellContent
                                                                    }
                                                                </Tooltip>
                                                            ) : (
                                                                cellContent
                                                            )
                                                    } else {
                                                        wrappedCellContent = (
                                                            <TextFormatter
                                                                style={{
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                value={toolTipValue(
                                                                    getValue(
                                                                        ci,
                                                                        a,
                                                                    ),
                                                                )}
                                                            />
                                                        )
                                                    }
                                                    return (
                                                        <TableCell
                                                            key={`${ci?.resource?.id}${a.name}`}
                                                            className={
                                                                classes.tableCell
                                                            }
                                                            style={{
                                                                padding:
                                                                    a.component
                                                                        ?.name ===
                                                                    'CredentialsLockedAttribute'
                                                                        ? 0
                                                                        : '15px 6px',
                                                            }}
                                                        >
                                                            {a.moreInfoIcon ? (
                                                                <Box display="flex">
                                                                    {
                                                                        wrappedCellContent
                                                                    }
                                                                    <ResourceListingInfoIcon
                                                                        attribute={
                                                                            a
                                                                        }
                                                                        data={
                                                                            ci?.resource
                                                                        }
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                wrappedCellContent
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}

                                            <TableCell
                                                className={
                                                    classes.tableActionCell
                                                }
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-end"
                                                    width={'100%'}
                                                    className={
                                                        classes.detailsBox
                                                    }
                                                >
                                                    <FieldTypeJsonPreview
                                                        ci={ci}
                                                        rightMargin={'1rem'}
                                                    />
                                                    {!isCredentialsSecretsTab && (
                                                        <Button
                                                            color={
                                                                theme.palette
                                                                    .secondary
                                                                    .main
                                                            }
                                                            onClick={() => {
                                                                onItemClick(ci)
                                                            }}
                                                        >
                                                            {t(
                                                                'ManageAccess_Details',
                                                            )}
                                                        </Button>
                                                    )}
                                                    <span
                                                        style={{ width: '5px' }}
                                                    />

                                                    {showActivateNow && (
                                                        <ActivateNowButton
                                                            resource={
                                                                ci.resource
                                                            }
                                                            resourceType={
                                                                currentResourceType
                                                            }
                                                            assignment={
                                                                ci?.assignment
                                                            }
                                                        />
                                                    )}

                                                    {isRevokable &&
                                                        !showActivateNow && (
                                                            <>
                                                                {!isRenewable && (
                                                                    <RevokeButton
                                                                        resourceType={
                                                                            currentResourceType.name
                                                                        }
                                                                        ci={ci}
                                                                    />
                                                                )}
                                                                {isRenewable && (
                                                                    <RenewButton
                                                                        resourceType={
                                                                            currentResourceType.name
                                                                        }
                                                                        cart={
                                                                            allCarts
                                                                        }
                                                                        ci={ci}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    {showCredentialActions && (
                                                        <CredentialsActionsButton
                                                            item={ci.resource}
                                                        />
                                                    )}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </>
                        ))}
                </TableBody>
            </Table>
        </>
    )
}

export default TableView
