import { Box, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from 'packages/eid-icons'
import { Pagination } from 'packages/eid-ui'
import Search from 'packages/eid-ui/GenericHeader/HeaderSearch'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { DEFAULT_PAGE_SIZE_DETAILS, paginate, useDebounce } from 'packages/core'
import RequiredLabel from '../Components/FieldItemWrapper/RequiredLabel'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import { Skeleton } from '@mui/material'
import CheckboxListWithInfoIcon from '../Components/CheckBoxListWithInfoIcon'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import InfoMessageForWrongJson from '../Components/InfoMessageForWrongJson'

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fbfbfd',
        margin: '0',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    header: {
        display: 'flex',
        marginTop: '20px',
        padding: '0px 30px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h3': {
            fontSize: '16px',
            fontWeight: '600',
        },
        '& p': {
            fontSize: '12px',
            color: '#5d6870',
        },
    },
    headerSearch: {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        borderRadius: '4px',
        maxWidth: '240px',
        width: '100%',

        '&:hover, &:focus': {
            borderColor: theme?.palette?.primary?.main,
        },

        '& input': {
            fontSize: '15px',
        },
        '& svg': {
            color: '#aab0b4',
            width: '18px !important',
            height: '18px !important',
        },
    },
    fieldList: {
        padding: '14px 30px 0',
        '& .MuiList-root': {
            columnCount: '2',
            columnGap: '1.5rem',
        },
        '& .MuiListItem-root': {
            padding: '0.75rem 0.625rem',
            borderBottom: '1px solid #efeff1',
            margin: '0',
            borderTop: '1px solid #efeff1',
        },
        '& p': {
            fontSize: '13px !important',
            fontWeight: '500',
            color: '#3b454d !important',
        },
        '&  div[class*="-paginationSection"] ': {
            padding: '0 !important',
        },
        '&  div[class*="-activeListItem"] p': {
            fontWeight: 'bold',
        },
    },
    paginationSection: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        padding: '24px 11px',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        '& .MuiSelect-select': {
            border: 'none',
            boxShadow: 'none',
        },
        '& .MuiInputBase-root': {
            border: 'solid 1px #ebebed',
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px !important',
            padding: '0 6px',
            background: theme?.palette?.background?.paper,
        },
    },
    selectedText: {
        color: `${theme?.palette?.success?.main} !important`,
    },
    disabledStyle: {
        pointerEvents: 'none',
        opacity: '.6',
        cursor: 'not-allowed !important',
    },
    errorMessage: {
        paddingLeft: '4rem !important',
        marginTop: '-1.2rem',
    },
}))

const MultiSelectCheckBoxList = ({
    fieldType,
    isFormValidated,
    valueType,
    isApiSupportedSource,
    useDataHook,
    handleUpdate,
}) => {
    const { t } = useTranslation()
    const [sourceValues, setSourceValues] = useState([])
    const [showFieldValues, setShowFieldValues] = useState(true)
    const [searchKey, setSearchKey] = useState('')
    const debouncedValue = useDebounce(searchKey)
    const classes = useStyles()
    const theme = useTheme()
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_DETAILS)
    const [errorMessage, setErrorMessage] = useState('')
    const [page, setPage] = useState(1)
    const [sourceUpdated, setSourceUpdated] = useState(false)
    let minimumRowsPerPage

    useEffect(() => {
        if (fieldType?.SourceValues && sourceUpdated) {
            setShowFieldValues(
                fieldType?.SourceValues?.length || searchKey ? true : false,
            )
            setSourceValues(fieldType?.SourceValues || [])
        }
    }, [fieldType?.SourceValues, searchKey, sourceUpdated])

    useEffect(() => {
        if (isFormValidated && !fieldType?.[valueType]?.length) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        } else {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType?.[valueType]])

    const { data: sourceData, isFetching } = useDataHook(
        fieldType,
        (page - 1) * pageSize,
        pageSize,
        debouncedValue,
    )

    const getFieldSourceData = () => {
        if (sourceValues?.length) {
            return sourceValues.map((item) => {
                return {
                    id: item.ValueName,
                    friendlyName: item.FriendlyName,
                    description: item.Description,
                }
            })
        } else {
            return []
        }
    }

    useEffect(() => {
        if (isApiSupportedSource) {
            if (!isFetching) {
                updateSourceValues()
                setSourceUpdated(true)
            }
        } else {
            setShowFieldValues(
                fieldType?.SourceValues?.length || searchKey ? true : false,
            )
            setSourceValues(fieldType?.SourceValues || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApiSupportedSource, isFetching, sourceData])

    const updateSourceValues = () => {
        const valueToUpdate = {
            ...fieldType,
            SourceValues: sourceData?.data || [],
            totalCount: sourceData?.totalCount,
        }
        handleUpdate(valueToUpdate)
    }

    const onChange = (item) => {
        const tempAssignedValues = fieldType?.[valueType] || []
        const currentIndex = tempAssignedValues.findIndex(
            (i) => i.ValueName === item.id,
        )
        const newChecked = [...tempAssignedValues]

        if (currentIndex === -1) {
            newChecked.push({
                FriendlyName: item.friendlyName,
                ValueName: item.id,
            })
        } else {
            newChecked.splice(currentIndex, 1)
        }

        const valueToUpdate = {
            ...fieldType,
            [valueType]: newChecked,
        }

        handleUpdate(valueToUpdate)
    }

    const handlePageChange = (_, value) => {
        setPage(value)
    }

    const handleSearch = (val) => {
        setSearchKey(val)
        setPage(1)
    }

    if(DEFAULT_PAGE_SIZE_DETAILS < 10){
        minimumRowsPerPage = DEFAULT_PAGE_SIZE_DETAILS
    }

    const getPaginatedItems = () => {
        if (isApiSupportedSource) {
            return {
                items: getFieldSourceData(),
                totalCount: fieldType?.totalCount,
            }
        }
        const filtered = getFieldSourceData().filter((item) =>
            item?.friendlyName?.toLowerCase().includes(searchKey.toLowerCase()),
        )
        return {
            items: paginate(filtered, page, pageSize),
            totalCount: filtered.length,
        }
    }
    return (
        <Box className={classes.root}>
            <Box>
                <Box className={classes.header}>
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        style={{
                            gap: '15px',
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    width: !showFieldValues
                                        ? '23.2rem'
                                        : 'auto',
                                }}
                                variant="h3"
                            >
                                {fieldType?.Name}{' '}
                                {fieldType?.FieldTypeScope && (
                                    <span>
                                        ({fieldType?.FieldTypeScope || '-'})
                                    </span>
                                )}
                            </Typography>
                            {fieldType?.IsRequired && <RequiredLabel />}
                        </Box>

                        {showFieldValues ? (
                            <Box
                                display="flex"
                                alignItems="center"
                                style={{
                                    gap: '8px',
                                }}
                            >
                                {fieldType?.[valueType]?.length ? (
                                    <Icon
                                        name="Check"
                                        color={theme?.palette?.success?.main}
                                        width="12px"
                                        height="14px"
                                    />
                                ) : null}
                                <Typography
                                    className={classNames({
                                        [classes.selectedText]:
                                            fieldType?.[valueType]?.length,
                                    })}
                                >{`${fieldType?.[valueType]?.length || 0} ${t(
                                    'Selected',
                                )}`}</Typography>
                            </Box>
                        ) : (
                            <Box>
                                <InfoMessageForWrongJson />
                            </Box>
                        )}
                    </Box>

                    {showFieldValues && (
                        <Box
                            className={classNames(classes.headerSearch, {
                                [classes.disabledStyle]: fieldType?.IsReadOnly,
                            })}
                        >
                            <Search
                                styleData={{}}
                                value={searchKey}
                                handleSearch={(val) => handleSearch(val)}
                                placeholder={t('QuickSearch')}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box
                className={classNames(classes.fieldList, {
                    [classes.disabledStyle]: fieldType?.IsReadOnly,
                })}
            >
                {/* Types List */}
                {isFetching ? (
                    <Fragment>
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                    </Fragment>
                ) : showFieldValues ? (
                    <CheckboxListWithInfoIcon
                        dataList={getPaginatedItems().items || []}
                        fontColor={theme?.palette?.common?.black}
                        itemContainerHeight="20px"
                        handleToggle={onChange}
                        checked={
                            fieldType?.[valueType]?.map((item) => {
                                return {
                                    id: item.ValueName,
                                    friendlyName: item.FriendlyName,
                                }
                            }) || []
                        }
                        highlightText={searchKey}
                        fallbackMessage={t('Common_NoDataFound')}
                        highlightSelected={true}
                        showInfoTooltip={true}
                    />
                ) : null}
            </Box>
            {Boolean(errorMessage) && (
                <Box>
                    <RequiredFieldErrorMessage
                        parentClass={classes.errorMessage}
                        errorMsgKey={errorMessage}
                    />
                </Box>
            )}
            {showFieldValues && (
                <Box>
                    <Box className={classes.paginationSection}>
                        <Pagination
                            count={
                                Math.ceil(
                                    getPaginatedItems()?.totalCount
                                        ? getPaginatedItems()?.totalCount /
                                              pageSize
                                        : 0,
                                ) || 0
                            }
                            size="large"
                            siblingCount={0}
                            page={page}
                            onChange={handlePageChange}
                            showItemsPerPageSelection={true}
                            itemsPerPage={pageSize}
                            onPageSelection={(gotoPage) => setPage(gotoPage)}
                            onItemsPerPageChange={(perPage) => {
                                setPage(1)
                                setPageSize(perPage)
                            }}
                            showPageSelection={true}
                            minimumRowsPerPage= {minimumRowsPerPage} 
                            totalCount={getPaginatedItems().totalCount || 0}
                            showCountStat={true}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default MultiSelectCheckBoxList
