import { Fragment, useEffect, useState } from 'react'
import { Box, styled, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import {
    Loader,
    Select,
    Attribute,
    AttributeLabel,
    AttributeValue,
} from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    useCart,
    useCheckComputerAccess,
    useTargetPerson,
    useAccessRequestPolicy,
    useCurrentPerson,
    GetComputerSharedCredentials,
    GetComputerPersonalCredentials,
} from 'hooks'
import { Icon } from 'packages/eid-icons'
import {
    DifferentiationValueSelector as ValueSelector,
    ItemDetails,
} from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { AddToCart } from './AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import { SelectorSection } from './SelectorSection'
import { useTimeConstrainedControl } from './TimeConstrainedControl'
import ComputerSessionRequest from 'components/ComputerSessionRequest'
import { Button } from 'components'
import { PreApprovedItems } from 'components/PreApprovedItems'

const useStyles = makeStyles((theme) => ({
    customselect: {
        width: '320px',
        '& span': {
            textOverflow: ' ellipsis',
            maxWidth: '280px',
            overflow: 'hidden',
            overflowWrap: 'nowrap',
        },
    },
    addToCartPreApproved: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    disabledButton: {
        '& button:nth-child(1) > div': {
            background: theme?.palette?.primary?.main,
        },
    },
    taskbadge: {
        position: 'relative',
        top: '12px',
        right: '16px',
        background: theme?.palette?.quaternary?.main,
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        fontSize: '10px',
        color: theme?.palette?.common?.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1px',
    },
    attributelabel: {
        minWidth: '220px',
    },
}))

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const ComputerDetails = ({ computer, toggleDrawer }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()

    const MEMBERSHIP_BASED_ACCESS = t('Computer_MembershipBasedAccess')
    const LOGIN_SESSION_ACCESS = t('Computer_LoginSessionAccess')
    const ONE_TIME_ACCESS = t('Computer_OneTimeAccess')
    const PRE_APPROVED_ACCESS = t('Computer_PreApprovedAccess')
    const PERSONAL_CREDENTIALS = t('Computer_PersonalCredentials')
    const SHARED_CREDENTIALS = t('Computer_SharedCredentials')

    const allRequestTypes = [
        { id: 1, friendlyName: MEMBERSHIP_BASED_ACCESS },
        { id: 2, friendlyName: LOGIN_SESSION_ACCESS },
    ]
    const allAccessTypes = [
        { id: 1, friendlyName: ONE_TIME_ACCESS },
        { id: 2, friendlyName: PRE_APPROVED_ACCESS },
    ]
    const allCredentialTypes = [
        { id: 1, friendlyName: PERSONAL_CREDENTIALS },
        { id: 2, friendlyName: SHARED_CREDENTIALS },
    ]

    const isSmallScreen = useIsSmallScreen()

    const [targetPerson] = useTargetPerson()

    const { data: cart } = useCart()

    const [activeTab, setActiveTab] = useState('overview')
    const [requestType, setRequestType] = useState(null)
    const [requestTypes, setRequestTypes] = useState(allRequestTypes)
    const [accessTypes, setAccessTypes] = useState(allAccessTypes)
    const [credentialType, setCredentialType] = useState(null)
    const [credentialTypes, setCredentialTypes] = useState(allCredentialTypes)
    const [accessType, setAccessType] = useState(null)
    const [accessLevel, setAccessLevelValue] = useState(null)
    const [credentials, setCredentials] = useState(null)
    const [showScheduledOptions, setShowScheduledOptions] = useState(false)

    const { data: currentPerson } = useCurrentPerson()
    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id

    const {
        data: personalCredentials,
        isLoading: loadingPersonalCredentials,
    } = GetComputerPersonalCredentials()

    const {
        data: accessLevels,
        isLoading: isCheckingAccess,
    } = useCheckComputerAccess(computer?.id, targetPerson.id)

    const requestPolicy = useAccessRequestPolicy(computer?.requestPolicyId)

    const computerAdditionalProps = {
        isPreApproved: accessType === PRE_APPROVED_ACCESS,
        personalCredentialId: credentials?.id,
    }

    const {
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        timeConstrainedControl,
    } = useTimeConstrainedControl(requestPolicy, true)

    const { data: sharedCredentials } = GetComputerSharedCredentials(
        computer?.id,
    )

    useEffect(() => {
        if (computer && sharedCredentials) {
            let showPersonalCred = true
            let showSharedCred = true
            let showMembershipBased = true
            let showLoginSession = true
            let newRequestTypes = []
            let newCredentialTypes = []

            if (computer && !computer.isMembershipEnabled) {
                showMembershipBased = false
            }
            if (
                accessLevels &&
                accessLevels.filter((o) => !o.isAssigned).length < 1
            ) {
                showMembershipBased = false
            }
            if (computer && !computer.isPsmEnabled) {
                showLoginSession = false
            }
            if (shoppingForSomeoneElse) {
                if (computer && computer.isPsmEnabled) {
                    if (!sharedCredentials?.availableExist) {
                        showLoginSession = false
                    }
                }

                if (!sharedCredentials?.availableExist) {
                    showSharedCred = false
                }
                showPersonalCred = false
            } else {
                if (computer && computer.isPsmEnabled) {
                    if (
                        !sharedCredentials?.availableExist &&
                        personalCredentials &&
                        personalCredentials.length === 0
                    ) {
                        showLoginSession = false
                    }
                }

                if (!sharedCredentials?.availableExist) {
                    showSharedCred = false
                }
                if (personalCredentials && personalCredentials.length === 0) {
                    showPersonalCred = false
                }
            }

            if (showMembershipBased && showLoginSession) {
                newRequestTypes = [...requestTypes]
            } else {
                if (showMembershipBased) {
                    const firstFilterType = requestTypes.filter(
                        (x) => x.friendlyName === MEMBERSHIP_BASED_ACCESS,
                    )
                    newRequestTypes = [...firstFilterType]
                }
                if (showLoginSession) {
                    const tempTypes =
                        newRequestTypes.length > 0
                            ? [...newRequestTypes]
                            : [...requestTypes]
                    const SecondFilterType = tempTypes.filter(
                        (x) => x.friendlyName === LOGIN_SESSION_ACCESS,
                    )
                    newRequestTypes = [...SecondFilterType]
                }
            }

            if (showSharedCred && showPersonalCred) {
                newCredentialTypes = [...credentialTypes]
            } else {
                if (showSharedCred) {
                    const firstFilterCredTypes = credentialTypes.filter(
                        (x) => x.friendlyName === SHARED_CREDENTIALS,
                    )
                    newCredentialTypes = [...firstFilterCredTypes]
                }

                if (showPersonalCred) {
                    const tempTypes =
                        newCredentialTypes.length > 0
                            ? [...newCredentialTypes]
                            : [...credentialTypes]
                    const secondFilterCredTypes = tempTypes.filter(
                        (x) => x.friendlyName === PERSONAL_CREDENTIALS,
                    )
                    newCredentialTypes = [...secondFilterCredTypes]
                }
            }

            if (computer && computer.isPreApproved) {
                const newAccessTypes = accessTypes.filter(
                    (x) => x.friendlyName !== PRE_APPROVED_ACCESS,
                )
                setAccessTypes(newAccessTypes)
            }
            setRequestTypes(newRequestTypes)
            setCredentialTypes(newCredentialTypes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [computer, personalCredentials, sharedCredentials, accessLevels])

    useEffect(() => {
        if (requestType?.friendlyName === LOGIN_SESSION_ACCESS) {
            setAccessLevelValue(null)
        }
        if (requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS) {
            setCredentialType(null)
            setCredentials(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestType])

    const registry = useRegistry()

    const computerType = registry.get(ResourceTypesNamespace, 'Computers')

    const getPreValidationMessage = () => {
        if (!Boolean(accessType)) {
            return t('Common_SelectAccessType')
        }
        if (accessType?.friendlyName !== PRE_APPROVED_ACCESS) {
            if (!Boolean(requestType)) {
                return t('Common_SelectRequestType')
            }

            if (
                requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS &&
                !Boolean(accessLevel)
            ) {
                return t('Common_SelectAccessLevel')
            }
            if (
                requestType?.friendlyName === LOGIN_SESSION_ACCESS &&
                !Boolean(credentialType)
            ) {
                return t('Common_SelectCredentialType')
            }
            if (
                credentialType?.friendlyName === PERSONAL_CREDENTIALS &&
                !Boolean(credentials)
            ) {
                return t('Common_SelectCredentials')
            }
            if (
                accessType?.friendlyName === ONE_TIME_ACCESS &&
                hasInvalidDates
            ) {
                return t('Common_InvalidDates')
            }
            if (accessLevels && accessLevels.isAssigned) {
                return t('ResourceAlreadyAssigned')
            }
        }
        return ''
    }

    const preAdd = () => {
        const itemToAdd = cartHelpers.computerToCartItem({
            targetPerson,
            assignmentType: 'Add',
            computer,
            isPreApproved: accessType?.friendlyName === PRE_APPROVED_ACCESS,
            assignmentStatus: accessLevel,
            computerAdditionalProps,
            timeConstrained: accessType?.friendlyName === ONE_TIME_ACCESS,
            startDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS ||
                showScheduledOptions
                    ? selectedStartDate
                    : null,
            endDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS ||
                showScheduledOptions
                    ? selectedEndDate
                    : null,
            computerAdditionalProperties: {
                personalCredentialID: credentials ? credentials.id : null,
                TypeOfAccess: accessType?.friendlyName
                    ? accessType.friendlyName
                    : computer?.isPreApproved
                    ? LOGIN_SESSION_ACCESS
                    : '',
                RequestType: requestType?.friendlyName
                    ? requestType.friendlyName
                    : computer?.isPreApproved
                    ? ONE_TIME_ACCESS
                    : '',
                CredentialType: credentialType?.friendlyName
                    ? credentialType.friendlyName
                    : '',
                CredentialFriendlyName: credentials
                    ? credentials.friendlyName
                    : '',
            },
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const handleCredentialsChange = (credential) => {
        setCredentials(credential)
    }

    const requestTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectRequestType')}
            iconName="RequestType"
        >
            <ValueSelector
                onChange={(val) => setRequestType(val)}
                data={requestTypes}
                value={requestType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const accessTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectAccessType')}
            iconName="MonitorClock"
        >
            <ValueSelector
                onChange={(val) => setAccessType(val)}
                data={accessTypes}
                value={accessType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const accessLevelSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectAccessLevel')}
            iconName="AccessLevel"
        >
            <ValueSelector
                loading={isCheckingAccess}
                onChange={(val) => setAccessLevelValue(val)}
                data={accessLevels && accessLevels.filter((o) => !o.isAssigned)}
                // NOTE: Keeping this for future refrence.
                // data={
                //     accessLevels &&
                //     accessLevels.filter(
                //         (o) =>
                //             !o.isAssigned &&
                //             o?.resourceAssignment?.assigneeId ===
                //             targetPerson.id,
                //     )
                // }
                value={accessLevel?.resourceAssignment.resourceAssignmentId}
                getOptionLabel={(o) => o.resourceAssignment.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.resourceAssignment.resourceAssignmentId ===
                    selectedValueId
                }
                readOnlyOptions={
                    accessLevels &&
                    accessLevels.filter(
                        (o) =>
                            o.isAssigned &&
                            o?.resourceAssignment?.assigneeId ===
                                targetPerson.id,
                    )
                }
            />
        </SelectorSection>
    )

    const durationSelectorSection = (
        <SelectorSection sectionLabel={t('Common_SelectDuration')}>
            <Box style={{ position: 'relative' }}>
                <Attribute orientation="horizontal">
                    <AttributeLabel className={classes.attributelabel}>
                        {t('Common_DefaultAccessDuration')}
                    </AttributeLabel>
                    <AttributeValue>
                        {requestPolicy && requestPolicy.defaultValueInMinutes}
                    </AttributeValue>
                </Attribute>

                <Attribute orientation="horizontal">
                    <AttributeLabel className={classes.attributelabel}>
                        {t('Common_MaxAccessDuration')}
                    </AttributeLabel>
                    <AttributeValue>
                        {requestPolicy && requestPolicy.maximumValueInMinutes}
                    </AttributeValue>
                </Attribute>
                <Box>{timeConstrainedControl}</Box>
            </Box>
        </SelectorSection>
    )

    const credentialsTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectCredentialType')}
            iconName="MonitorSettings"
        >
            <ValueSelector
                onChange={(val) => setCredentialType(val)}
                data={credentialTypes}
                value={credentialType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const credentialsSelectorSection = (
        <SelectorSection
            parentStyle={{
                padding: '18px 31px 20px 31px',
                minHeight: '100px',
            }}
            sectionLabel={t('Common_SelectCredentials')}
        >
            {personalCredentials && (
                <Box className={classes.customselect}>
                    <Select
                        placeholder={t('Common_SelectCredentials')}
                        options={personalCredentials}
                        loading={loadingPersonalCredentials}
                        onChange={handleCredentialsChange}
                        value={credentials}
                        getOptionLabel={(option) => option.friendlyName}
                    />
                </Box>
            )}
        </SelectorSection>
    )

    const getPreValidationMessageForScheduleAccessPreApproved = () => {
        if (!Boolean(credentialType)) {
            return t('Common_SelectCredentialType')
        }
        if (
            credentialType?.friendlyName === PERSONAL_CREDENTIALS &&
            !Boolean(credentials)
        ) {
            return t('Common_SelectCredentials')
        }
        if (hasInvalidDates) {
            return t('Common_InvalidDates')
        }
        if (accessLevels && accessLevels.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }

        const checkIfAlreadyExistInCart = cart.cartItems.find(
            (x) => x.requestableResourceId === computer.id,
        )
        if (checkIfAlreadyExistInCart) {
            return t('Common_ItemAlreadyInCart')
        }
        return ''
    }

    const getRequestTypeSelection = () => {
        return (
            <>
                {accessType?.friendlyName &&
                    accessType?.friendlyName !== PRE_APPROVED_ACCESS &&
                    requestTypeSelectorSection}
            </>
        )
    }

    const getCredetialSelection = () => {
        return (
            <>
                {accessType?.friendlyName !== PRE_APPROVED_ACCESS &&
                    requestType?.friendlyName === LOGIN_SESSION_ACCESS &&
                    credentialsTypeSelectorSection}
            </>
        )
    }

    const getAccessLevelSelection = () => {
        return (
            <>
                {accessType?.friendlyName !== PRE_APPROVED_ACCESS &&
                    requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS &&
                    accessLevels?.filter((o) => !o.isAssigned)?.length > 0 &&
                    accessLevelSelectorSection}
            </>
        )
    }

    if (!cart) return <Loader />
    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />

                <ItemDetails.Tabs.Tab
                    value="sessionRequest"
                    label={t('Common_SessionRequest')}
                />
                <span className={classes.taskbadge}>
                    {computer.checkOutCount}
                </span>
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <OverviewSection computer={computer} policy={requestPolicy} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection computer={computer} />
            )}
            {activeTab === 'sessionRequest' && (
                <ComputerSessionRequest item={computer} />
            )}

            {activeTab !== 'sessionRequest' && (
                <>
                    {computer && accessLevels && !shoppingForSomeoneElse && (
                        <PreApprovedItems
                            handleViewRequest={() =>
                                setActiveTab('sessionRequest')
                            }
                            computer={computer}
                            personResourceAssignments={accessLevels}
                            showScheduledOptions={showScheduledOptions}
                            setShowScheduledOptions={() =>
                                setShowScheduledOptions(!showScheduledOptions)
                            }
                        />
                    )}
                    {/* Section for scheduled if preapproved and want to schedule start */}

                    {showScheduledOptions && (
                        <>
                            {!isSmallScreen && (
                                <Fragment>
                                    {credentialsTypeSelectorSection}
                                    {credentialType?.friendlyName ===
                                        PERSONAL_CREDENTIALS &&
                                        credentialsSelectorSection}
                                    {durationSelectorSection}
                                    {/* Section for Request access button and cancel for sceduled */}
                                    <Box
                                        className={classes.addToCartPreApproved}
                                    >
                                        <Box
                                            className={
                                                !getPreValidationMessageForScheduleAccessPreApproved() &&
                                                classes.disabledButton
                                            }
                                        >
                                            <AddToCart
                                                resourceType={computerType}
                                                resource={computer}
                                                secondary={accessLevel}
                                                preValidate={
                                                    getPreValidationMessageForScheduleAccessPreApproved
                                                }
                                                preAdd={preAdd}
                                                postAdd={toggleDrawer}
                                                type={'RequestAccess'}
                                            />
                                        </Box>
                                        <Box
                                            style={{
                                                height: '80px',
                                                position: 'relative',
                                                alignItems: 'center',
                                                display: 'flex',
                                                marginBottom: '16px',
                                            }}
                                        >
                                            <Button
                                                rootStylesProp={{
                                                    width: '113px',
                                                    height: '48px',
                                                    borderRadius: '5px',
                                                    backgroundColor:
                                                        theme?.palette?.common
                                                            ?.white,
                                                    border: `solid 1px ${theme?.palette?.error?.main}`,
                                                    margin:
                                                        '16px 49px 16px 36px',
                                                    float: 'right',
                                                    color:
                                                        theme?.palette?.error
                                                            ?.main,
                                                }}
                                                onClick={() =>
                                                    setShowScheduledOptions(
                                                        !showScheduledOptions,
                                                    )
                                                }
                                            >
                                                <Icon
                                                    name="Close"
                                                    color={
                                                        theme?.palette?.error
                                                            ?.main
                                                    }
                                                    height="16px"
                                                    width="16px"
                                                />
                                                <Typography
                                                    style={{
                                                        paddingLeft: '12px',
                                                    }}
                                                >
                                                    {t('Common_Cancel')}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Fragment>
                            )}
                        </>
                    )}

                    {/* Section for scheduled if preapproved and want to schedule End */}
                    {!loadingPersonalCredentials &&
                        (!computer?.isPreApproved ||
                            shoppingForSomeoneElse) && (
                            <>
                                {!isSmallScreen && (
                                    <Fragment>
                                        {accessTypeSelectorSection}
                                        {getRequestTypeSelection()}
                                        {getCredetialSelection()}
                                        {credentialType?.friendlyName ===
                                            PERSONAL_CREDENTIALS &&
                                            credentialsSelectorSection}
                                        {getAccessLevelSelection()}
                                        {accessType?.friendlyName ===
                                            ONE_TIME_ACCESS &&
                                            durationSelectorSection}
                                    </Fragment>
                                )}

                                {/* Need to setup UI for small screen as per requirements */}
                                {isSmallScreen && (
                                    <PaddedDiv>
                                        <Box
                                            paddingY="24px"
                                            display="flex"
                                            flexDirection="column"
                                            position="relative"
                                        >
                                            <Box
                                                position="absolute"
                                                top="16px"
                                                right="0"
                                            >
                                                <Icon
                                                    name="AccessLevel"
                                                    height="100px"
                                                    width="100px"
                                                    color="#D2D2D9"
                                                />
                                            </Box>
                                            <Box
                                                width="172px"
                                                paddingY="4px"
                                                zIndex={1}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        lineHeight: 1.43,
                                                        fontWeight: 600,
                                                        textTransform:
                                                            'uppercase',
                                                        wordBreak: 'break-word',
                                                    }}
                                                >
                                                    {t(
                                                        'Common_SelectAccessLevel',
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box paddingY="16px">
                                                {' '}
                                                <ValueSelector
                                                    loading={isCheckingAccess}
                                                    onChange={(val) =>
                                                        setAccessLevelValue(val)
                                                    }
                                                    data={
                                                        accessLevels &&
                                                        accessLevels.filter(
                                                            (o) =>
                                                                !o.isAssigned,
                                                        )
                                                    }
                                                    value={
                                                        accessLevel
                                                            ?.resourceAssignment
                                                            .resourceAssignmentId
                                                    }
                                                    getOptionLabel={(o) =>
                                                        o.resourceAssignment
                                                            .friendlyName
                                                    }
                                                    checkSelectedOption={(
                                                        o,
                                                        selectedValueId,
                                                    ) =>
                                                        o.resourceAssignment
                                                            .resourceAssignmentId ===
                                                        selectedValueId
                                                    }
                                                    readOnlyOptions={
                                                        accessLevels &&
                                                        accessLevels.filter(
                                                            (o) => o.isAssigned,
                                                        )
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </PaddedDiv>
                                )}

                                <AddToCart
                                    resourceType={computerType}
                                    resource={computer}
                                    secondary={accessLevel}
                                    preValidate={getPreValidationMessage}
                                    preAdd={preAdd}
                                    postAdd={toggleDrawer}
                                />
                            </>
                        )}
                </>
            )}
        </Fragment>
    )
}

export default ComputerDetails
