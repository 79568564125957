import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { TextInput } from 'packages/eid-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles({
    freesingleValue: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        '& input': {
            maxWidth: '13rem',
            color: '#3b454d',
        },
        '& .MuiBox-root': {
            maxWidth: '13rem',
            width: '100%',
        },
        '& .MuiFormControl-root': {
            marginTop: '0 !important',
            maxWidth: '13rem',
            borderRadius: '4px',
        },
        '& .MuiInputBase-formControl': {
            minWidth: '13rem',
        },
    },

    errorField: {
        '& input': {
            outline: '1px solid #d0021b',
        },
    },
    disabledStyle: {
        pointerEvents: 'none',
        opacity: '.6',
        cursor: 'not-allowed',
    },
})

const FreeTextMultiValues = ({
    fieldType,
    isFormValidated,
    valueType,
    handleUpdate,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const [errorMessage, setErrorMessage] = useState('')
    const [values, setValues] = useState(
        fieldType?.[valueType]
            ? fieldType?.[valueType]
            : [{ ValueName: '', FriendlyName: '' }],
    )
    const handleChange = (value, index) => {
        const updatedValues = [...values]
        updatedValues[index] = { ...updatedValues[index], ValueName: value }
        setValues(updatedValues)
        const valueToUpdate = {
            ...fieldType,
            [valueType]: updatedValues,
        }
        handleUpdate(valueToUpdate)
    }

    const handleAddField = () => {
        setValues([...values, { ValueName: '', FriendlyName: '' }])
    }

    useEffect(() => {
        if (isFormValidated && !fieldType?.[valueType]?.length) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        } else {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType?.[valueType]])

    return (
        <FieldItemWrapper
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
        >
            <Box
                flexWrap={'wrap'}
                display="flex"
                alignItems="center"
                gap="0.8rem"
            >
                {values.map((item, index) => (
                    <Box
                        key={index}
                        className={classNames(classes.freesingleValue, {
                            [classes.errorField]: Boolean(errorMessage),
                            [classes.disabledStyle]: fieldType?.IsReadOnly,
                        })}
                    >
                        <TextInput
                            handleChange={(e) =>
                                handleChange(e.target.value, index)
                            }
                            value={item.ValueName}
                            placeholder={t('Common_EnterValue')}
                            disabled={fieldType?.IsReadOnly}
                        />
                    </Box>
                ))}
                <Box
                    onClick={handleAddField}
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '3.8rem',
                        width: '3.8rem',
                        padding: '1rem',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                >
                    <Icon name="Plus" />
                </Box>
            </Box>
            {Boolean(errorMessage) && (
                <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
            )}
        </FieldItemWrapper>
    )
}

export default FreeTextMultiValues
