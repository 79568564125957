import { FC, Fragment } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useHistory } from 'react-router'
import appConfig from 'config'
import RevokeButton from 'containers/ManageAccess/RevokeButton'
import { PreApprovedItemWrapper } from 'components/PreApprovedItems/WrapperForPreApprovedAccessItem'
import { Pagination } from 'packages/eid-ui'
import { hasValidJson, updateJsonMappingWithApiFormat, useIsSmallScreen } from 'packages/core'
import ActivateNowButton from 'containers/ListingPage/ActivateNowButton'
import { FieldTypeValuesAssignmentPopup } from 'packages/eid-ui'

export type ProtectedApplicationAssignmentInfoProps = {
    title: string
    resourceAssignment?: any
    resourceType: any
    totalCount?: number
    pageCount?: number
    setPage?: (value: any) => void
    page?: any
    manageAccessRedirectTab: string
    applicationId: string
    applicationTypeRegistry: any
}

const useStyles = makeStyles((theme) =>
    createStyles({
        preapprovecard: {
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.09)',
            borderRadius: '8px',
            padding: '15px 16px',
            background: theme?.palette?.common?.white,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '16px 0',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab936e 7%, #2c83bd47 91%, rgba(48, 127, 193, 0))',
            borderImageSlice: '1',
            borderBottomWidth: '1px',
            borderStyle: 'solid',
            borderTopWidth: '0',
        },
        preapprovecardleft: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        preapprovecardright: {
            display: 'flex',
            gap:'1rem',
            '& button': {
                textTransform: 'capitalize',
                '& p': {
                    textTransform: 'capitalize',
                },
            },
        },

        manageAccessButton: {
            background: theme?.palette?.common?.white,
            padding: '7px 8px 7px 13px',
            border: `solid 1px ${theme?.palette?.primary?.main}`,
            borderRadius: '4px',
            fontSize: '14px',
            lineHeight: '1',
            color: theme?.palette?.primary?.main,
            textTransform: 'capitalize',
            '& span': {
                fontSize: '14px',
                marginLeft: '0px',
            },
        },

        preapprovebtn: {
            border: 'solid 1px #aab0b4',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '1',
            padding: '4px 16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#262fa1',
        },
        membershipbtn: {
            background: theme?.palette?.common?.white,
            borderRadius: '5px',
            color: theme?.palette?.tertiary?.main,
        },
        preApprovedbtn: {
            background: theme?.palette?.common?.white,
            borderRadius: '5px',
            color: theme?.palette?.secondary?.main,
        },
        preapproveadmin: {
            marginLeft: '15px',
            fontSize: '14px',
            color: theme?.palette?.common?.black,
            textTransform: 'capitalize',
        },
        preapprovedsection: {
            display: 'flex',
            alignItems: 'center',
        },
        taskbadgesection: {
            position: 'relative',
        },
        taskbadge: {
            position: 'absolute',
            top: '-15px',
            right: '-10px',
            background: theme?.palette?.quaternary?.main,
            height: '15px',
            width: '15px',
            borderRadius: '50%',
            fontSize: '10px',
            color: theme?.palette?.common?.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '1px',
        },
    }),
)

export const ProtectedApplicationAssignmentInfo: FC<
    ProtectedApplicationAssignmentInfoProps
> = (props: ProtectedApplicationAssignmentInfoProps) => {
    const { t } = useTranslation()
    const history = useHistory()
    const {
        resourceAssignment,
        title,
        resourceType,
        totalCount = 0,
        pageCount = 1,
        setPage,
        page,
        manageAccessRedirectTab,
        applicationId,
        applicationTypeRegistry,
    } = props
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreen()
    const theme = useTheme()



    const getParsedObject = (apiDataObject: any) => {
        try {
            const parsedObject = Array.isArray(apiDataObject)
                ? apiDataObject[0]
                : apiDataObject
            return parsedObject
        } catch (err) { }
        return apiDataObject
    }

    return (
        <>
            <PreApprovedItemWrapper
                defaultExpand={false}
                showExpand={resourceAssignment?.length ? true : false}
                title={title}
                itemCount={totalCount}
            >
                <Fragment>
                    {resourceAssignment?.length &&
                        resourceAssignment.map((item: any, index: number) => {
                            const resource = item?.resource
                            return (
                                <Box
                                    key={`application-assignment-tile-${title}-${index}`}
                                    className={classes.preapprovecard}
                                >
                                    <Box className={classes.preapprovecardleft}>
                                        <Box
                                            className={
                                                classes.preapprovedsection
                                            }
                                        >
                                            <Box
                                                className={classNames(
                                                    classes.preapprovebtn,
                                                    {
                                                        [classes.membershipbtn]:
                                                            resource?.isAssigned,
                                                        [classes.preApprovedbtn]:
                                                            !resource?.isAssigned,
                                                    },
                                                )}
                                            >
                                                {resource?.isAssigned
                                                    ? t('Assigned')
                                                    : t('Common_PreApproved')}
                                            </Box>
                                            <Box>
                                                <Icon name="ArrowIconGreen" />
                                            </Box>
                                        </Box>
                                        <Box
                                            className={classes.taskbadgesection}
                                        >
                                            <Typography
                                                className={
                                                    classes.preapproveadmin
                                                }
                                            >
                                                {resource?.friendlyName ?? '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        className={classes.preapprovecardright}
                                    >
                                        {item?.resource
                                            ?.assignedFieldTypeValuePreviewJson &&
                                            hasValidJson(
                                                updateJsonMappingWithApiFormat(
                                                    getParsedObject(
                                                        JSON.parse(
                                                            item.resource
                                                                ?.assignedFieldTypeValuePreviewJson,
                                                        ),
                                                    ),
                                                    false,
                                                ),
                                            ) && (
                                                <Box>
                                                    <FieldTypeValuesAssignmentPopup
                                                        fieldTypeValueJson={updateJsonMappingWithApiFormat(
                                                            getParsedObject(
                                                                JSON.parse(
                                                                    item
                                                                        .resource
                                                                        ?.assignedFieldTypeValuePreviewJson,
                                                                ),
                                                            ),
                                                            false,
                                                        )}
                                                        withBorder={true}
                                                        showLabel={false}
                                                    />
                                                </Box>
                                            )}
                                        {item?.assignment?.canActivateNow && (
                                            <ActivateNowButton
                                                resource={item?.resource}
                                                resourceType={resourceType}
                                                width="90px"
                                                assignment={item?.assignment}
                                                forCardView={true}
                                                resourceId={applicationId}
                                                resourceAssignmentId={
                                                    //For applications, we need to pass resourceId from resourceAssignment Object as resourceAssignmentId
                                                    item?.assignment
                                                        ?.resourceAssignment
                                                        ?.resourceId
                                                }
                                            />
                                        )}
                                        {item.assignment?.assignmentDetails
                                            ?.isRevokable && (
                                                <RevokeButton
                                                    resourceType={
                                                        applicationTypeRegistry
                                                    }
                                                    item={{
                                                        resource: resource,
                                                        assignment: item.assignment,
                                                    }}
                                                    buttonStyles={{
                                                        minWidth: '72px',
                                                    }}
                                                    postRevoke={() => { }}
                                                />
                                            )}
                                        <Button
                                            className={
                                                classes.manageAccessButton
                                            }
                                            onClick={() =>
                                                history.push(
                                                    `${appConfig.APP_SUBPATH
                                                    }/applications/manageAccess?tab=${manageAccessRedirectTab}&selected=${resource?.manageAccessId ||
                                                    resource.id
                                                    }`,
                                                )
                                            }
                                            endIcon={
                                                <Icon
                                                    name="ArrowSmall"
                                                    width="20px"
                                                    fill={
                                                        theme?.palette?.primary
                                                            ?.main
                                                    }
                                                    direction="right"
                                                />
                                            }
                                            size={'medium'}
                                        >
                                            {t('Common_Manage')}
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        })}
                    {pageCount > 1 && (
                        <Box
                            padding={!isSmallScreen ? '20px 32px' : '16px'}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Pagination
                                count={pageCount}
                                size={!isSmallScreen ? 'large' : 'small'}
                                page={page}
                                onChange={(_: any, value: any) =>
                                    typeof setPage === 'function' &&
                                    setPage(value)
                                }
                            />
                        </Box>
                    )}
                </Fragment>
            </PreApprovedItemWrapper>
        </>
    )
}
