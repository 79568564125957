import React from 'react'
import { Grow, Menu, MenuItem, MenuList } from '@mui/material'
import { styled } from '@mui/styles'
import LanguageMenuItem from './LanguageMenuItem'
import { Backdrop } from '../Backdrop'

const StyledMenu = styled(
    ({ rootStyles, listStyles, menuHeight, width, ...rest }) => (
        <Menu {...rest} />
    ),
)(({ rootStyles, listStyles, menuHeight, width }) => ({
    '& .MuiMenu-paper': {
        borderRadius: '8px',
        minWidth: '100px',
        width: width ? width : '240px',
        overflow: 'visible',
        paddingRight: '4px',
        transition:
            'opacity 0.25s ease-in-out, transform 0.25s ease-in-out !important',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        ...rootStyles,

        '& .gradient-border': {
            content: '',
            display: 'block',
            height: '1px',
            position: 'absolute',
            bottom: '-1px',
            width: '100%',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0) 100%)',
            borderImageSlice: '1',
            opacity: '0.5',
        },

        '& .MuiMenu-list': {
            overflowX: 'visible',
            '& > ul': {
                maxHeight: menuHeight && menuHeight,
                overflow: 'auto',
                '&:focus': { outline: 'none' },
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    margin: '4px',
                    '-webkit-border-radius': '3px',
                    borderRadius: '3px',
                    backgroundColor: '#f7f7f7',
                },

                '&::-webkit-scrollbar-thumb': {
                    width: '3px',
                    borderRadius: '5px',
                    border: '2px solid transparent',
                    height: '20px',
                    backgroundColor: '#d8d8d8',
                },

                '& > li': {
                    paddingLeft: '18px',
                    paddingRight: '16px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    transition: 'all 0.15s ease-in-out',
                    borderLeft: '2px solid transparent',
                },

                '& > li.Mui-selected': {
                    backgroundColor: 'transparent !important',
                    color: '#307fc1',
                    borderLeft: '2px solid #307fc1',

                    '& svg': {
                        color: 'inherit',
                        transition: 'color 0.15s ease-in-out',
                    },
                },
                '& > li:hover': {
                    backgroundColor: '#eff6fc !important',
                    color: '#000',
                    borderLeft: '2px solid #307fc1',

                    '& svg': {
                        color: '#000',
                    },
                },
            },
            ...listStyles,
        },
    },
}))

const ConeShapedDiv = styled('span')(({ position }) => ({
    width: 0,
    height: 0,
    borderLeft: '14px solid transparent',
    borderRight: '14px solid transparent',
    borderBottom: '14px solid #ffffff',
    position: 'absolute',
    right: '25px',
    top: '-13px',
    ...position,
}))

const StyledMenuItem = styled(({ style, ...rest }) => <MenuItem {...rest} />)(
    ({ style }) => ({
        minWidth: '160px',
        color: '#4a4a4a',
        fontSize: '14px',
        '&:hover, &:focus': {
            backgroundColor: '#e0ecf6 !important',
            color: '#000000',
        },
        '& svg': {
            marginRight: '10px',
        },
        ...style,
    }),
)

const Dropdown = (props) => {
    const {
        open,
        anchorEl,
        anchorOriginPosition,
        anchorTransformOrigin,
        handleClose,
        handleListKeyDown,
        children,
        menuHeight,
        width,
        rootStyles,
        listStyles,
        backdrop,
        showCone,
        iconPosition,
        expandButton,
        disableScrollLock,
        ...rest
    } = props
    return (
        <StyledMenu
            rootStyles={rootStyles}
            listStyles={listStyles}
            menuHeight={menuHeight}
            width={width}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={open}
            onClose={handleClose}
            anchorOrigin={anchorOriginPosition}
            transformOrigin={anchorTransformOrigin}
            TransitionComponent={Grow}
            onKeyDown={handleListKeyDown}
            disableScrollLock={disableScrollLock}
            BackdropComponent={backdrop && Backdrop}
            {...rest}
        >
            {showCone && <ConeShapedDiv position={iconPosition} />}

            <MenuList autoFocus={false} disabledItemsFocusable={false}>
                {children}
            </MenuList>
            {expandButton}
            <div className="gradient-border"></div>
        </StyledMenu>
    )
}

Dropdown.Item = StyledMenuItem
Dropdown.LanguageMenuItem = LanguageMenuItem

Dropdown.defaultProps = {
    showCone: false,
    anchorOriginPosition: {
        vertical: 'top',
        horizontal: 'right',
    },
    anchorTransformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    disableScrollLock: true,
}

export default Dropdown
