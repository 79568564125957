import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import InputWithInnerLabel from '../Components/InputWithInnerLabel'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import Tooltip from 'packages/eid-ui/Tooltip'

const FreeTextMultiValue = ({
    valueType,
    fieldType,
    AzLocalRightID,
    isFormValidated,
    handleUpdate,
}) => {
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (isFormValidated) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType])

    const handleChange = (value, itemKey, index) => {
        try {
            let selectedIndexItem = [...fieldType?.[valueType]]
            selectedIndexItem[index][itemKey] = value

            const valueToUpdate = {
                ...fieldType,
                [valueType]: selectedIndexItem,
                parentRightId: AzLocalRightID,
            }

            handleUpdate(valueToUpdate)
        } catch (err) {}
    }
    return (
        <FieldItemWrapper
            headerStyle={{ alignItems: 'baseline' }}
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
            childStyle={{ width: '100%' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.6rem',
                    width: '100%',
                    '& .MuiInputBase-formControl': {
                        width: '90%',
                    },
                    '& input': {
                        boxShadow: 'none',
                        fontSize: '1.5rem',
                        textTransform: 'capitalize',
                        color: '#3b454d',
                        overflow: 'hidden',
                        width: '100%',
                    },
                    '& .MuiFormControl-root': {
                        marginTop: '0 !important',
                    },
                }}
            >
                {fieldType?.[valueType]?.length
                    ? fieldType?.[valueType]?.map((item, index) => (
                          <Box
                              key={`field-type-free-multi-${index}`}
                              sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1.8rem',
                                  '& .MuiFormControl-root': {
                                      height: '4rem',
                                      display: 'flex',
                                      alignItems: 'center',
                                      borderRadius: '.4rem',
                                  },
                                  '& label': {
                                      fontSize: '1rem !important',
                                      fontWeight: 'bold',
                                  },
                              }}
                          >
                              <Box
                                  sx={{
                                      width: '100%',
                                      '& .MuiFormControl-root': {
                                          background: '#f1f1f1',
                                      },
                                  }}
                              >
                                  <InputWithInnerLabel
                                      value={item?.FriendlyName}
                                      placeholder={t('Common_Key')}
                                      label={t('Common_Key')}
                                      disabled={true}
                                      showTooltip={true}
                                      sx={{ maxWidth: '34rem' }}
                                  />
                              </Box>
                              <Tooltip title={item?.ValueName}>
                                  <Box
                                      sx={{
                                          boxShadow:
                                              '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
                                          width: '100%',
                                          '& label': {
                                              width: '3.7rem',
                                          },
                                      }}
                                  >
                                      <InputWithInnerLabel
                                          value={item?.ValueName}
                                          placeholder={t('Common_Value')}
                                          label={t('Common_Value')}
                                          handleChange={(e) =>
                                              handleChange(
                                                  e.target.value,
                                                  'ValueName',
                                                  index,
                                              )
                                          }
                                          sx={{ maxWidth: '34rem' }}
                                      />
                                  </Box>
                              </Tooltip>
                          </Box>
                      ))
                    : null}
                <Box paddingLeft={'.2rem'}>
                    {Boolean(errorMessage) && (
                        <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
                    )}
                </Box>
            </Box>
        </FieldItemWrapper>
    )
}

export default FreeTextMultiValue
