import { Layout, Loader, ScrollToTopButton } from 'packages/eid-ui'
import ITShopHeader from './ITShopHeader'
import ITShopGlobalMenu from './ITShopGlobalMenu'
import ScrollToTop from 'react-scroll-up'
import config from 'config'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useAppState } from 'appContext'
import { Box, styled } from '@mui/material'
import useSubcomponents from 'useSubcomponents'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import { headerHeight } from 'utils'

const SubHeaderContainer = styled(Box)(
    ({ hideHeader, hideSideNavigation, isSmallScreen }) => ({
        position: 'fixed',
        top: hideHeader ? 0 : headerHeight,
        width: '100%',
        maxWidth: '1920px',
        height: '80px',
        display: 'flex',
        alignItems: 'end',
        paddingTop: '0',
        paddingRight: isSmallScreen ? '0px' : '20px',
        paddingLeft: isSmallScreen
            ? '0px'
            : hideSideNavigation
                ? '25px'
                : '90px',
        backgroundColor: 'transparent',
        zIndex: 1099,


        '&::before': {
            content: '""',
            display: 'block',
            backgroundColor: 'rgb(238, 240, 244)',
            position: 'absolute',
            left: 0,
            top: '-4px',
            width: '100%',
            height: 'calc(100% + 1px)',
        },
    }),
)

const ITShopLayout = (props) => {
    const { t } = useTranslation()
    const history = useHistory()
    const isSmallScreen = useIsSmallScreen()
    const [loading, setLoading] = useState(true)
    const { children, subHeader, currentResourceType } = props
    const [{ appConfig }] = useAppState()
    const query = useQuery()
    const showFilters = query.get('filters') === 'visible'
    const showScrollToTopButton = config.SHOW_SCROLL_TO_TOP && !showFilters
    const hideHeader = appConfig.style.header.hide
    const hideSideNavigation = appConfig.style.hideSideNavigation
    const isManageAccess =
        history.location.pathname
            .toLowerCase()
            .indexOf('/manageAccess'.toLowerCase()) >= 0
    const isWorkFlowTab =
        history.location.pathname
            .toLowerCase()
            .indexOf('/workflows'.toLowerCase()) >= 0
    const isRequestAccessTab = !isManageAccess && !isWorkFlowTab

    const { hasAccessToTabs } = useSubcomponents()

    const {
        hasAccessToRequestAccess,
        hasAccessToManageAccess,
        hasAccessToWorkFlow,
    } = hasAccessToTabs(currentResourceType?.name)

    useEffect(() => {
        // Case 1: On request access tab and n0 access
        if (isRequestAccessTab && !hasAccessToRequestAccess) {
            history.push(`${config.APP_SUBPATH}${currentResourceType.route}/manageAccess`)
        }

        // Case 2: On manage access tab and no access
        if (isManageAccess && !hasAccessToManageAccess) {
            // Case 2.1: On manage access and has access to request access
            if (hasAccessToRequestAccess) {
                history.push(`${config.APP_SUBPATH}${currentResourceType.route}`)
            }
            // Case 2.2: On manage access and no access to request access too. Then check for workflow
            else if (hasAccessToWorkFlow) {
                history.push(`${config.APP_SUBPATH}${currentResourceType.route}/workflows`)
            }
        }
        // Case 3: On Workflow tab and no access
        if (isWorkFlowTab && !hasAccessToWorkFlow) {
            // Case 3.1: On manage access and has access to request access
            if (hasAccessToRequestAccess) {
                history.push(`${config.APP_SUBPATH}${currentResourceType.route}`)
            }
            // Case 3.2: On manage access and no access to request access too. Then check for workflow
            else if (hasAccessToManageAccess) {
                history.push(`${config.APP_SUBPATH}${currentResourceType.route}/manageAccess`)
            }
        }
        setLoading(false)
    }, [
        isRequestAccessTab,
        isManageAccess,
        hasAccessToRequestAccess,
        hasAccessToManageAccess,
        hasAccessToWorkFlow,
        history,
    ])

    const styles = {
        rootStyles: {
            ...(showScrollToTopButton && {
                position: 'relative',
                paddingBottom: '30px',
            }),
            ...(hideHeader && { inset: '0px !important' }),
        },
        scrollButton: {
            bottom: 20,
            right: 20,
            zIndex: 1199,
        },
        contentContainerStyles: {
            ...(!isSmallScreen && hideSideNavigation && { marginLeft: '17px' }),
        },
    }

    const scrollToTopComponent = (
        <ScrollToTop showUnder={160} style={styles.scrollButton}>
            <ScrollToTopButton size="medium" label={t('Common_BackToTop')} />
        </ScrollToTop>
    )

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <Layout
                    header={!hideHeader && <ITShopHeader />}
                    globalMenu={
                        <ITShopGlobalMenu
                            hideSideNavigation={hideSideNavigation}
                        />
                    }
                    children={children}
                    scrollToTopComponent={
                        showScrollToTopButton && scrollToTopComponent
                    }
                    rootStyles={styles.rootStyles}
                    contentContainerStyles={styles.contentContainerStyles}
                    subHeader={
                        subHeader && (
                            <SubHeaderContainer
                                hideHeader={hideHeader}
                                hideSideNavigation={hideSideNavigation}
                                isSmallScreen={isSmallScreen}
                            >
                                {subHeader}
                            </SubHeaderContainer>
                        )
                    }
                />
            )}
        </>
    )
}

export default ITShopLayout
