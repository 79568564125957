import { Box, styled } from '@mui/material'
import { FC, Fragment, useState } from 'react'
import { ApplicationAppRights } from './AppRights'
import { ApplicationManagementRolesAssignments } from './ManagementRoleAssignments'
import { ApplicationRoleDefinitions } from './RoleDefinitions'

export type ProtectedApplicationAssignmentsProps = {
    applicationId: string
    applicationsType: any
    application: any
    applicationTypeRegistry: any
}

const GradientSpacer = styled(Box)({
    background:
        'linear-gradient(180deg, #e9e9eb 0%, #f7f7f9 50%, #f1f1f3 100%)',
    height: '2.4rem',
    marginTop: '2.4rem',
})

export const ProtectedApplicationAssignments: FC<
    ProtectedApplicationAssignmentsProps
> = (props: ProtectedApplicationAssignmentsProps) => {
    const [showSpacer, setShowSpacer] = useState(false)

    return (
        <Fragment>
            {showSpacer && <GradientSpacer />}
            {props.application.allowAppRoleDirectAssignment && (
                <ApplicationAppRights
                    setShowSpacer={setShowSpacer}
                    {...props}
                />
            )}

            {props.application.allowAppManagementRoleDirectAssignment && (
                <ApplicationManagementRolesAssignments
                    setShowSpacer={setShowSpacer}
                    {...props}
                />
            )}

            {props.application.allowRoleDefinitionDirectAssignment && (
                <ApplicationRoleDefinitions
                    setShowSpacer={setShowSpacer}
                    {...props}
                />
            )}
        </Fragment>
    )
}
