import { FC, useState } from 'react'
import { Box, Collapse, styled, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { Icon } from 'packages/eid-icons'

import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

export type PreApprovedItemProps = {
    title: string
    itemCount?: number
    defaultExpand?: any
    showExpand?: any
    infoSubtext?: string
    boldHeader?: boolean
}

const PaddedDiv = styled(Box)({
    margin: '0 20px',
})

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            background: '#fbfbfd',
            marginBottom: '16px',
        },
        approveaccordionleft: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        approveaccordionright: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        approveaccordion: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 35px',
            borderBottom: '1px solid #d8dadd',
            background: 'transparent',
        },
        approveheading: {
            fontSize: '14px',
            color: '#282828',
        },
        approvecounting: {
            padding: '0px 8px',
            marginLeft: '8px',
            background: theme?.palette?.grey?.[900],
            borderRadius: '8px',
            fontSize: '14px',
            color: theme?.palette?.common?.white,
        },
        approvehide: {
            fontSize: '14px',
            color: theme?.palette?.primary?.main,
            marginRight: '8px',
            cursor: 'pointer',
        },
        expandIcon: {
            width: '1.6rem',
            height: '1.6rem',
            marginLeft: '0.8rem',
            marginTop: '-0.1rem',
            color: theme?.palette?.primary?.main,
        },
        expandButton: {
            textTransform: 'uppercase',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        headerBold: {
            fontSize: '14px',
            fontWeight: 'bold',
        },
        infoSubtext: {
            fontSize: '10px',
            marginTop: '5px',
        },
    }),
)

export const PreApprovedItemWrapper: FC<PreApprovedItemProps> = (props) => {
    const {
        title,
        itemCount,
        children,
        defaultExpand = true,
        showExpand = true,
        infoSubtext = '',
        boldHeader = false,
    } = props
    const { t } = useTranslation()
    const [showExpanded, setShowExpanded] = useState<boolean>(defaultExpand)
    const classes = useStyles()
    const theme = useTheme()

    return (
        <>
            <Box className={classes.approveaccordion}>
                <Box className={classes.approveaccordionleft}>
                    <Box
                        className={classNames(classes.approveheading, {
                            [classes.headerBold]: boldHeader,
                        })}
                    >
                        {title ? title : '-'}
                    </Box>
                    {itemCount ? (
                        <Box className={classes.approvecounting}>
                            {itemCount}
                        </Box>
                    ) : infoSubtext ? (
                        <Box
                            className={classNames(
                                classes.approvecounting,
                                classes.infoSubtext,
                            )}
                        >
                            {infoSubtext}
                        </Box>
                    ) : null}
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    fontSize="12px"
                    fontWeight="bold"
                    color={theme?.palette?.primary?.main}
                >
                    {showExpand && (
                        <Box
                            component="span"
                            display="flex"
                            className={classes.expandButton}
                            onClick={() => setShowExpanded(!showExpanded)}
                        >
                            {showExpanded ? t('Hide') : t('Show')}
                            <Box className={classes.expandIcon}>
                                {showExpanded ? (
                                    <Icon
                                        name="MinusCircle"
                                        width="1.6rem"
                                        height="1.6rem"
                                    />
                                ) : (
                                    <Icon
                                        name="PlusCircle"
                                        width="1.6rem"
                                        height="1.6rem"
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            <Collapse in={showExpanded} timeout={400} unmountOnExit>
                <PaddedDiv className={classes.root}>{children}</PaddedDiv>
            </Collapse>
        </>
    )
}
