import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import {
    useApplication,
    useCheckApplicationsAccess,
    useTargetPerson,
} from 'hooks'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ApplicationDetails from './ApplicationDetails'
import { useEffect, useState } from 'react'
import { useResourceTypeContext } from 'resourceTypeContext'

const ApplicationsDrawer = ({
    id,
    toggleDrawer,
    item,
    currentResourceType,
}) => {
    const [accessLevel, setAccessLevelValue] = useState(null)
    const [accessLevels, setAccessLevels] = useState([])
    const isSmallScreen = useIsSmallScreen()
    const [targetPerson] = useTargetPerson()
    const [{ referencePerson }] = useResourceTypeContext()

    const { data } = useApplication(id)

    const {
        data: accessLevelsData,
        isLoading: isCheckingAccess,
    } = useCheckApplicationsAccess(id, referencePerson?.id, data)

    useEffect(() => {
        if (accessLevel && accessLevels && accessLevels.length === 1) {
            setAccessLevelValue(accessLevels[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessLevels, setAccessLevelValue])

    useEffect(() => {
        if (accessLevelsData) {
            const tempLevels = [
                ...(accessLevelsData?.managementRoles || []),
                ...(accessLevelsData?.localRights || []),
                ...(accessLevelsData?.azureRoles || []),
            ]
            if (tempLevels?.length) {
                // Converting to resourceAssignment key to keep the exiting components working
                const levels = tempLevels.map((item) => {
                    return {
                        resourceAssignment: {
                            ...item,
                        },
                    }
                })
                setAccessLevels(levels)
            }
        } else {
            setAccessLevels([])
        }
        setAccessLevelValue(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessLevelsData])

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={data?.friendlyName} />
            {!data && <Loader />}
            {data && (
                <ApplicationDetails
                    application={data}
                    accessLevel={accessLevel}
                    accessLevels={accessLevels}
                    setAccessLevelValue={(val) => setAccessLevelValue(val)}
                    isCheckingAccess={isCheckingAccess}
                    targetPerson={targetPerson}
                    applicationId={id}
                    item={item}
                    currentResourceType={currentResourceType}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="clamp(67%, 1007px, 90%)"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={data?.friendlyName} />}
        >
            {!data && <Loader />}
            {data && (
                <ApplicationDetails
                    application={data}
                    accessLevel={accessLevel}
                    accessLevels={accessLevels}
                    setAccessLevelValue={(val) => setAccessLevelValue(val)}
                    isCheckingAccess={isCheckingAccess}
                    targetPerson={targetPerson}
                    applicationId={id}
                    item={item}
                    currentResourceType={currentResourceType}
                />
            )}
        </Drawer>
    )
}

export default ApplicationsDrawer
