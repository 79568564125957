import { Box } from '@mui/material'
import { useApplicationAppRights, useTargetPerson } from 'hooks'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { ProtectedApplicationAssignmentInfo } from '../AssignmentTile'

const take = 3

export type ApplicationAppRightsProps = {
    applicationId: string
    applicationsType: any
    applicationTypeRegistry: any
    setShowSpacer: any
}

export const ApplicationAppRights: FC<ApplicationAppRightsProps> = (
    props: ApplicationAppRightsProps,
) => {
    const [page, setPage] = useState(1)
    const {
        applicationId,
        applicationsType,
        applicationTypeRegistry,
        setShowSpacer,
    } = props
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()
    const { data, isLoading } = useApplicationAppRights(
        applicationId,
        targetPerson.id,
        (page - 1) * take,
        take,
    )

    const pageCount = data?.totalCount ? Math.ceil(data.totalCount / take) : 0

    useEffect(() => {
        if (data?.data?.length) {
            setShowSpacer(true)
        }
    }, [data])

    const bindAppRight = () => {
        try {
            if (data?.data?.length) {
                return data.data.map((item: any) => {
                    return {
                        resource: {
                            ...item,
                            description:
                                item?.description || item.assignmentPointName,
                            requestPolicyId: item.accessRequestPolicyId,
                            id: item?.localRightId,
                            manageAccessId: item?.id //NOTE: Adding this as per API change and not to break the generic function logic in FE
                        },
                        assignment: {
                            personId: targetPerson.id,
                            isAssigned: item.isAssigned,
                            canActivateNow: item.canActivateNow,
                            isRenewable: item.isRenewable,
                            assignmentId: item?.id,
                            resourceAssignment: {
                                id: item.applicationId,
                                friendlyName: item.assignmentPointName,
                                resourceAssignmentId: item?.localRightId,
                            },
                            assignmentDetails: {
                                isRevokable: item.isRevocable,
                                timeConstraintActive: item.timeConstraintActive,
                                startDateUtc: item.startDateUtc,
                                endDateUtc: item.endDateUtc,
                            },
                        },
                    }
                })
            }
        } catch (err) {}
        return []
    }

    return (
        <Box>
            {isLoading ? (
                <Skeleton height={40} />
            ) : data?.data?.length ? (
                <Box>
                    <ProtectedApplicationAssignmentInfo
                        totalCount={data?.totalCount}
                        setPage={(value: any) => setPage(value)}
                        pageCount={pageCount}
                        resourceAssignment={bindAppRight() || []}
                        title={t('Common_AppRights')}
                        resourceType={applicationsType}
                        manageAccessRedirectTab="appRights"
                        applicationId={applicationId}
                        applicationTypeRegistry={applicationTypeRegistry}
                    />
                </Box>
            ) : null}
        </Box>
    )
}
