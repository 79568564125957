import { Fragment, useState } from 'react'
import { ItemDetails, LicenseDetailsCard, Switch } from 'components'
import { Box, Typography } from '@mui/material'
import { CollapsibleList } from 'packages/eid-ui'
import {
    useAzureLicenseServicePlans,
    useCheckAzureLicenseAccess,
    useTargetPerson,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import cartHelpers from 'containers/Cart/cartHelpers'
import useSubcomponents from 'useSubcomponents'
import { OverviewSection } from './OverviewSection'
import { AddToCart } from 'components/AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { Skeleton } from '@mui/material';

const AzureLicenseDetails = ({ azureLicense, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()

    const azureLicenseType = registry.get(
        ResourceTypesNamespace,
        'AzureLicenses',
    )

    const [activeTab, setActiveTab] = useState('overview')

    const [targetPerson] = useTargetPerson()

    const { canSeeAzureLicenseServicePlansSection } = useSubcomponents()

    const { data: servicePlansResponse } = useAzureLicenseServicePlans(
        azureLicense.azureTenantSubscriptionId,
    )

    const {
        data: assignmentStatus,
        isLoading: isCheckingAccess,
    } = useCheckAzureLicenseAccess(azureLicense.id, targetPerson.id)

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.azLicenseToCartItem({
            targetPerson,
            assignmentType: 'Add',
            azureLicense,
            assignmentStatus,
            ...baseObj,
        })

        if (!itemToAdd) return undefined

        return itemToAdd
    }
    const getPreValidationMessage = () => {
        if (assignmentStatus && assignmentStatus.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <OverviewSection azureLicense={azureLicense} />
            )}

            <Box paddingLeft="32px">
                <CollapsibleList
                    data={{
                        heading: t('AzureLicenses_HighLevelClassification'),
                        list: [
                            {
                                label: azureLicense.highLevelClassification,
                            },
                        ],
                    }}
                    getLabel={(item) => item.label}
                />
            </Box>

            {canSeeAzureLicenseServicePlansSection && servicePlansResponse && (
                <Box
                    padding="24px 30px 16px 32px"
                    marginBottom="15px"
                    borderBottom="1px solid #efeff1"
                    style={{ backgroundColor: '#fbfbfd' }}
                    data-protectedsubcomponent={canSeeAzureLicenseServicePlansSection}
                >
                    <LicenseDetailsCard
                        title={t('AzureLicenses_ServicePlans')}
                        subTitle={
                            azureLicense.azureTenantSubscription_FriendlyName
                        }
                        collapsible={true}
                        expanded={false}
                    >
                        {servicePlansResponse.length === 0 && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                width="100%"
                            >
                                {t('Common_NoDataFound')}
                            </Box>
                        )}

                        {servicePlansResponse.map((item) => (
                            <Box
                                key={item.id}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                            >
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        wordBreak: 'break-word',
                                        padding: '4px 0px',
                                    }}
                                >
                                    {item.localServiceFriendlyName}
                                </Typography>

                                <Switch
                                    disabled={true}
                                    onLabel={t('Common_On')}
                                    offLabel={t('Common_Off')}
                                    value={!item.disabled}
                                />
                            </Box>
                        ))}
                    </LicenseDetailsCard>
                </Box>
            )}

            {isCheckingAccess ? (
                <Box padding={'24px'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <AddToCart
                    preAdd={preAdd}
                    resourceType={azureLicenseType}
                    resource={azureLicense}
                    postAdd={toggleDrawer}
                    preValidate={getPreValidationMessage}
                />
            )}
        </Fragment>
    )
}

export default AzureLicenseDetails
